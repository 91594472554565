import * as React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { HotKeys } from "react-hotkeys";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Beforeunload } from 'react-beforeunload';

import { xor, mapKeys, upperFirst } from "lodash";
import * as R from "ramda";
import axios from "axios";
import memoize from "memoize-weak";

import * as Bowser from "bowser";

import ActiveUserObserver from "../../helpers/Doc/Observers/activeUserObserver";
import BeforeInputObserver from "../../helpers/Doc/Observers/beforeInputObserver";
import CopyObserver from "../../helpers/Doc/Observers/copyObserver";
import MouseClickObserver from "../../helpers/Doc/Observers/mouseClickObserver";
import OfflineObserver from "../../helpers/Doc/Observers/offlineObserver";
import SelectionObserver from "../../helpers/Doc/Observers/selectionObserver";

import generateUUID from "../../helpers/generateUUID";
import { full_url } from "../../helpers/url";

import { weakMemoizedReflow } from "../../helpers/Doc/Reflow";

import * as selection from "../../helpers/Doc/selection";

import { organizeMediaAssets } from "../../helpers/media_manager";

import {
  weakMemoizedApplyAllChanges,
  weakMemoizedSetBlockNodesContainsSelection,
  weakMemoizedAttachCurrentMenuToNode,
  changesDisplayModes,
  newPublishChange,
  newSetKeyValueChange,
  clotheChange,
  addChangeToDoc,
  computeSpacedChangeSummaries,
  findFlatFlowNodesWithFlowRange,
  translateSelectionToFlowRange,
  flatFlowToTreeFlow,
  weakMemoizedConvertAllToFlatFlowAndInlineRangeJSON,
  stripExtraChangeDataFields,
} from "../../helpers/Doc/changes";

import {
  publishVersionId,
  ongoingMaintenaceVersionId,
  determineVersionType,
  determineVariantTypeFromVersionType,
  variantKeyFromVariantVersion,
} from "../../helpers/Doc/version_types";

import * as undoRedo from "../../helpers/Doc/undoRedoState";

import {
  findNodeLineageByUid,
  findNodeByUid,
  editNodeWithUIDKeyValue,
  findNodePathByUid,
  setAtPath,
  findAtPath,
  findNodes,
  findNodesPaths,
  adjustSelectionStartToIncludeListItemIfNeeded,
} from "../../helpers/Doc/Functions/base";

import { postDocumentUserLog } from "../../helpers/DocumentUserLog";

import * as RangeInlineJSON from "../../helpers/Doc/Functions/RangeInlineJSON";

import {
  nodeDefinitions,
} from "../../helpers/Doc/Define/base";

import * as DocConvertHTML from "../../helpers/Doc/Convert/HTML";

import {generateDocumentActions} from "../../reducers/documents";
import {generateDocumentUserStateActions} from "../../reducers/documentUserStates";
import {
  generateLearningObjectiveActions,
  learningObjectiveFromStatePlus,
} from "../../reducers/learningObjectives";
import {generateReusableObjectActions} from "../../reducers/reusableObjects";
import {
  generateVariantActions,
  variantFromStatePlus,
} from "../../reducers/variants";
import {generateVersionActions} from "../../reducers/versions";
import {reasoningToolActionCreators} from "../../reducers/ui/reasoningTool";

import { addDocumentUserState } from "../../helpers/Doc/documentUserState";

import {
  weakMemoizedGenerateCurrentDocument,
  weakMemoizedGenerateCurrentUser,
  weakMemoizedGenerateCurrentVersion,
  weakMemoizedGenerateDisplayContext,
  weakMemoizedGenerateEditingContext,
  weakMemoizedGenerateEditingContextAllFalse,
  weakMemoizedGenerateIntegrationInfo,
} from "../../helpers/Doc/context";

import { scrollToElementWithUid } from "../../helpers/Doc/scrollTo";

import {
  NEW_SUMMARY_OF_EDITS,
  NEW_COMMENT_THREAD,
  EDIT_COMMENT,
  FIND_AND_REPLACE,
  EDIT_LINK,
  ADD_NODE,
  TEXT_COLOR_PICKER,
} from "../../helpers/Doc/menu"

import deleteAtSelection from "../../helpers/Doc/EditActions/delete";
import insertAtSelection from "../../helpers/Doc/EditActions/insert";
import pasteAtSelection from "../../helpers/Doc/EditActions/paste";
import returnAtSelection from "../../helpers/Doc/EditActions/return";
import shiftTabAtSelection from "../../helpers/Doc/EditActions/shiftTab";
import tabAtSelection from "../../helpers/Doc/EditActions/tab";
import toggleStyleAtSelection from "../../helpers/Doc/EditActions/toggleStyle";

import { displayModes } from "../../helpers/Doc/displayMode";
import { trackChangesEditModes } from "../../helpers/Doc/trackChangesEditMode";

import { POSSIBLE_RO_CLASSES } from "../../helpers/RO/content_type";

import NewEditDoc from "./Component";

import GenLoadingSpinner from "../Gen/LoadingSpinner/component";

function replaceInObjectKeys(object, toReplace: string, replacement: string) {
  return mapKeys(object, (val, key) => key.replace(toReplace, replacement).replace(upperFirst(toReplace), upperFirst(replacement)));
}

function computedGenerateDocumentActionCreators(docType?: DocType) { // TODO: Change to "ReusableObject" | "Document"
  if (docType === "ReusableObject") {
    return ((id) => replaceInObjectKeys(generateReusableObjectActions(id), "reusableObject", "document")) as typeof generateDocumentActions;
  } else {
    return generateDocumentActions;
  }
}

function computedGenerateVersionActionCreators(docType?: DocType) { // TODO: Change to "ReusableObject" | "Document"
  if (docType === "ReusableObject") {
    return ((id) => replaceInObjectKeys(generateVariantActions(id), "variant", "version")) as typeof generateVersionActions;
  } else {
    return generateVersionActions;
  }
}

interface OwnProps {
  currentUser: User;
  documentId: number;
  isAdmin: boolean;
  permissions: AqPermissions;
  versionId: number;

  activeMemberId?: number;
  activeMemberName?: string;
  activeUserId?: number;
  assessmentQuestion?: AssessmentQuestion;
  assessmentQuestionEditingData?: AssessmentQuestionEditingData;
  assessmentQuestionSignatureVersion?: Version;
  assessmentQuestionSignatureVersionId?: number;
  assessmentQuestionStatistics?: Array<AssessmentStatistic>;
  assessmentStatus?: string;
  cableApp?: {cable: ActionCable.Consumer};
  cciStatusOptions?: Array<CciStatusOptions>;
  checklistContents?: Array<ChecklistContent>;
  currentAssignmentId?: number;
  currentGroupRoleDefinition?: GroupRoleDefinition;
  defaultChangesDisplayMode?: ChangesDisplayMode;
  displayUid?: string;
  docType?: DocType;
  documentSetId?: number;
  editors?: Array<EditorSpecialType>;
  enableAiSummaryStatementFeedback?: boolean;
  featureFlag?: FeatureFlag;
  isEditor?: boolean;
  isEmbeded?: boolean;
  isNotGated?: boolean;
  mappingData?: MappingContext;
  nextVersionId?: number;
  possiblePrerequisiteDocuments?: Array<Document>;
  projectProductionAssociate?: string;
  scrollable_uids?: Array<string>;
  sessionId?: number;
  shouldShowEditor?: boolean;
  usedAvailableForm?: boolean;
  allLearningObjectiveOptions?: Array<{id: number, name: string, documents: Array<{id: number, title: string}>}>;
}

function findVariantPlaceholderNodePaths<T>(data: DocSectionType<T>) {
  return findNodesPaths(data, (node) => node.type == "variantPlaceholder")
}

function generateChangeData(variant: Variant | Version) {
  if (!variant.changeData || R.isEmpty(variant.changeData)) {
    return weakMemoizedConvertAllToFlatFlowAndInlineRangeJSON(variant.data);
  } else {
    return variant.changeData
  }
}

function applyChangesToVariant(variant: Variant | Version, publishVariantId: number, changesDisplayMode: ChangesDisplayMode, useChangeData: boolean) {
  if (variant?.data) {
    if (useChangeData && variant.id !== publishVariantId) {
      return weakMemoizedApplyAllChanges(generateChangeData(variant), changesDisplayMode);
    } else {
      return variant.data;
    }
  }
}

const mapStateToProps = (state: ReducerState, ownProps: OwnProps) => {
  const {
    docType,
    currentUser,
    documentId,
    versionId,
    assessmentQuestionSignatureVersionId,
  } = ownProps;

  const generateUsableDocumentActions = computedGenerateDocumentActionCreators(docType);
  const usableDocumentActionCreators = generateUsableDocumentActions(documentId);

  const documentProps = {
    document: usableDocumentActionCreators.documentSyncDataFromState(state),
    documentSyncMeta: usableDocumentActionCreators.documentSyncMetaFromState(state),
    documentExtraData: usableDocumentActionCreators.documentExtraDataFromState(state),
  };

  const selectedVersionId = documentProps.documentExtraData?.versionId || versionId;

  const generateUsableVersionActions = computedGenerateVersionActionCreators(docType);
  const usableVersionActionCreators = generateUsableVersionActions(selectedVersionId);

  const versionProps = {
    version: usableVersionActionCreators.versionMergedDataFromState(state),
    versionSyncMeta: usableVersionActionCreators.versionSyncMetaFromState(state),
    versionEditData: usableVersionActionCreators.versionEditDataFromState(state),
    versionExtraData: usableVersionActionCreators.versionExtraDataFromState(state),
  };

  let changesDisplayMode: ChangesDisplayMode;
  if (versionProps.version?.data) {
    if (ownProps.shouldShowEditor || ownProps.defaultChangesDisplayMode) {
      if (versionProps.version.id === documentProps?.document?.publishVersionId) {
        changesDisplayMode = changesDisplayModes.ONLY_PUBLISHED;
      } else {
        changesDisplayMode = versionProps.versionExtraData?.changesDisplayMode || ownProps.defaultChangesDisplayMode || changesDisplayModes.HIGHLIGHT_SUGGESTIONS;
      }
    } else {
      changesDisplayMode = null // TODO: Remove null
    }
  }

  const useChangeData = ownProps.shouldShowEditor || !!ownProps.defaultChangesDisplayMode
  let mergedData = applyChangesToVariant(versionProps.version, documentProps?.document?.publishVersionId, changesDisplayMode, useChangeData)

  const variantPlaceholderPaths = mergedData ? findVariantPlaceholderNodePaths(mergedData) : []
  const learningObjectivePlaceholderPaths = variantPlaceholderPaths.filter((vpPath) => findAtPath(mergedData, vpPath).variantableType === "LearningObjective").filter((path) => !path.includes("addDeleteChanges"))
  const learningObjectiveNodePathsById = Object.fromEntries(learningObjectivePlaceholderPaths.map((loPlaceholderPath) => [findAtPath(mergedData, loPlaceholderPath).variantableId, loPlaceholderPath]))
  const learningObjectiveIds = Object.keys(learningObjectiveNodePathsById)
  const localLearningObjectives: Array<ReducerItemWithMerged<LearningObjective, LearningObjectiveExtraData>> = learningObjectiveIds.map((learningObjectiveId) => learningObjectiveFromStatePlus(learningObjectiveId, state))
  const learningObjectives = localLearningObjectives;

  let localLearningObjectiveVariants: Array<ReducerItemWithMerged<Variant, VariantExtraData>> = []
  let variants: Array<ReducerItemWithMerged<Variant, VariantExtraData>> = []
  const variantMergedDataById: Record<number, MergedData> = {}
  if (documentProps.document) {
    const versionType = determineVersionType(documentProps.document as any, selectedVersionId); // TODO: Remove any
    const variantType = determineVariantTypeFromVersionType(versionType);
    const variantKey = variantKeyFromVariantVersion(variantType);

    localLearningObjectiveVariants = localLearningObjectives
      .filter((learningObjective) => learningObjective?.mergedData?.[variantKey])
      .map((learningObjective) => variantFromStatePlus(learningObjective?.mergedData?.[variantKey], state))

    localLearningObjectiveVariants.forEach((learningObjectiveVariant) => {
      if (learningObjectiveVariant?.mergedData) {
        const parentLearningObjective = localLearningObjectives.find((lo) => lo?.mergedData?.[variantKey] === learningObjectiveVariant.id)
        const learningObjectiveNodePath = learningObjectiveNodePathsById[parentLearningObjective.id]
        const appliedData = applyChangesToVariant(learningObjectiveVariant.mergedData, parentLearningObjective?.mergedData?.publishVariantId, changesDisplayMode, useChangeData)
        const varaintPlaceholder = findAtPath(mergedData, learningObjectiveNodePath)

        variantMergedDataById[learningObjectiveVariant.id] = appliedData

        mergedData = setAtPath(mergedData, learningObjectiveNodePath, appliedData)
        mergedData = setAtPath(mergedData, learningObjectiveNodePath.concat(['variantId']), learningObjectiveVariant.id)
        mergedData = setAtPath(mergedData, learningObjectiveNodePath.concat(['variantUid']), varaintPlaceholder.uid)
      }
    })

    variants = localLearningObjectiveVariants;
  }

  const unsavedChanges =  !!versionProps.versionEditData?.changeData || !!versionProps.versionEditData?.coverImage || variants.some((variant) => variant.editData?.changeData)

  const dusId = docType === "ReusableObject" || !currentUser ? "TEMP_ID" : `${documentId}/${currentUser.id}` // TODO TEMP_ID
  const documentUserStateActions = generateDocumentUserStateActions(dusId)
  const currentUserProps = {
    documentUserState: documentUserStateActions.documentUserStateMergedDataFromState(state),
  };

  const extraProps = docType !== "ReusableObject" ? { assessmentQuestionSignatureVersion: generateVersionActions(assessmentQuestionSignatureVersionId).versionSyncDataFromState(state) } : {}

  return {
    changesDisplayMode,
    mergedData,
    variantMergedDataById,
    variants,
    learningObjectives,
    selectedVersionId,
    unsavedChanges,
    ...documentProps,
    ...versionProps,
    ...currentUserProps,
    localLearningObjectives,
    localLearningObjectiveVariants,
    reasoningToolUi: state.ui.reasoningTool,
    getUploadCountByMediaType: (type:  MediaType) => versionProps.versionExtraData[`${type}UploadCount`] || 0,
    ...extraProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const {
    docType,
  } = ownProps;

  return {
    documentActions: (id: number) => bindActionCreators(computedGenerateDocumentActionCreators(docType)(id), dispatch),
    versionActions: (id: number) => bindActionCreators(computedGenerateVersionActionCreators(docType)(id), dispatch),
    documentUserStateActions: (id: number | string) => bindActionCreators(generateDocumentUserStateActions(id), dispatch),
    learningObjectiveActions: (id: number) => bindActionCreators(generateLearningObjectiveActions(id), dispatch),
    variantActionsGenerator: (id: number) => bindActionCreators(generateVariantActions(id), dispatch),
    reusableObjectActions: (id: number) => bindActionCreators(generateReusableObjectActions(id), dispatch),
    reasoningToolActions: bindActionCreators(reasoningToolActionCreators, dispatch),
    dispatch: dispatch,
  };
};

function sharedDocFunctions(dispatchProps: ReturnType<typeof mapDispatchToProps>, documentId: number, versionId: number, documentUserStateId: number | string, currentUser: User) {
  const basicFuncs = {
    variantActionsGenerator: dispatchProps.variantActionsGenerator,
    learningObjectiveActionsGenerator: dispatchProps.learningObjectiveActions,
    ...dispatchProps.documentActions(documentId),
    ...dispatchProps.versionActions(versionId),
    ...dispatchProps.documentUserStateActions(documentUserStateId),
    ...dispatchProps.reasoningToolActions,
  };

  return {
    ...basicFuncs,
    setDisplayMode: (mode: DisplayMode) => basicFuncs.setExtraDataVersion({displayMode: mode}),
    setCurrentDocSelection: (data: DocSelection) => {
      window.forceDocSelection = data;
      basicFuncs.setExtraDataVersion({
        currentDocSelection: data,
        newActiveStyles: [],
        newInactiveStyles: [],
      });
      selection.setDocSelection(data);
    },
    clearCurrentDocSelection: () => {
      selection.setDocSelection(undefined);
      basicFuncs.setExtraDataVersion({
        currentDocSelection: undefined,
        newActiveStyles: [],
        newInactiveStyles: [],
      });
    },
    setCurrentMenu: (menu, data = {}) => basicFuncs.setExtraDataVersion({currentMenu: {menu, ...data}}),
    closeCurrentMenu: () => basicFuncs.setExtraDataVersion({currentMenu: undefined}),
    setLocalClipboard: (data?: LocalClipboardData) => basicFuncs.setExtraDataVersion({localClipboard: data}),
    setIsMouseDown: (data?: boolean) => basicFuncs.setExtraDataVersion({isMouseDown: data}),
    setFindText: (data?: string) => basicFuncs.setExtraDataVersion({findText: data}),
    setNewActiveStyles: (data?: Array<StyleName>) => basicFuncs.setExtraDataVersion({newActiveStyles: data}),
    setNewInactiveStyles: (data?: Array<StyleName>) => basicFuncs.setExtraDataVersion({newInactiveStyles: data}),
    setReplaceText: (data?: string) => basicFuncs.setExtraDataVersion({replaceText: data}),
    setVersionId: (versionId: number) => basicFuncs.setExtraDataDocument({versionId}),
    setTrackChangesEditMode: (mode: TrackChangesEditMode) => basicFuncs.setExtraDataVersion({trackChangesEditMode: mode}),
    setChangesDisplayMode: (mode: ChangesDisplayMode) => basicFuncs.setExtraDataVersion({changesDisplayMode: mode}),
    setFocusedBookmarkUid: (uid: string) => basicFuncs.setExtraDataVersion({focusedBookmarkUid: uid}),
    setChangeSummaries: (changeSummaries: Array<OffsetChangeSummary>) => basicFuncs.setExtraDataVersion({changeSummaries: changeSummaries}),
    setAllowLearningObjectivesEdit: (allowLearningObjectivesEdit?: string) => basicFuncs.setExtraDataVersion({allowLearningObjectivesEdit}),
    changeUploadCountForMediaTypeByValue: (type: MediaType, uploadCountChange: number) => basicFuncs.setExtraDataWithFunctionVersion((extraData) => {
      const oldUploadCount = extraData[`${type}UploadCount`] || 0;
      return {...extraData,
        [`${type}UploadCount`]: oldUploadCount + uploadCountChange,
      };
    }),
    addChanges: (newChanges: Array<Change>, variantId?: number) => {
      const addChangesDispatch: any = (dispatch, getState) => { // TODO: REMOVE ANY
        const state = getState();

        const trackChangesEditMode = state.versions[versionId].extraData.trackChangesEditMode;

        const setAllDataWithFunction = variantId ?
        basicFuncs.variantActionsGenerator(variantId).setAllDataWithFunctionVariant :
        basicFuncs.setAllDataWithFunctionVersion

        setAllDataWithFunction((syncData, editData: Variant | Version, extraData: VariantExtraData | VersionExtraData) => {
          let oldChangeData = editData.changeData || syncData.changeData;

          if (!oldChangeData || R.isEmpty(oldChangeData)) {
            oldChangeData = weakMemoizedConvertAllToFlatFlowAndInlineRangeJSON(syncData.data);
          }

          // TODO didCompressAll
          const didCompressAll = false;

          const currentDocSelection = extraData.currentDocSelection;
          const newUndoRedoState = undoRedo.addToUndoStack(extraData.undoRedoState, {changeData: oldChangeData, currentDocSelection: currentDocSelection}, didCompressAll);

          const isSuggestingMode = trackChangesEditMode === trackChangesEditModes.SUGGESTING_MODE;

          const changeSetId = generateUUID();
          newChanges = newChanges.map((change) => ({...change, change_set_id: changeSetId}));
          const clothedChanges = newChanges.map((newChange) => clotheChange(newChange, currentUser, isSuggestingMode));
          const newChangeData = clothedChanges.reduce((doc, change) => addChangeToDoc(doc, change), oldChangeData);

          return {
            syncData,
            editData: {
              ...editData,
              changeData: newChangeData,
            },
            extraData: {
              ...extraData,
              undoRedoState: newUndoRedoState,
            },
          };
        });
      }

      dispatchProps.dispatch(addChangesDispatch)
    },
    undo: () => {
      basicFuncs.setAllDataWithFunctionVersion((syncData, editData, extraData) => {
        const changeData = editData.changeData || syncData.changeData;
        const undoRedoState = extraData.undoRedoState;
        const currentDocSelection = extraData.currentDocSelection;

        const [returnedState, newUndoRedoState] = undoRedo.undo(
          undoRedoState,
          {
            changeData: changeData,
            currentDocSelection: currentDocSelection,
          },
        );

        selection.setDocSelection(returnedState.currentDocSelection);

        return {
          syncData,
          editData: {
            ...editData,
            changeData: returnedState.changeData,
          },
          extraData: {
            ...extraData,
            undoRedoState: newUndoRedoState,
            currentDocSelection: returnedState.currentDocSelection,
            newActiveStyles: [],
            newInactiveStyles: [],
          },
        };
      });
    },
    redo: () => {
      basicFuncs.setAllDataWithFunctionVersion((syncData, editData, extraData) => {
        const changeData = editData.changeData || syncData.changeData;
        const undoRedoState = extraData.undoRedoState;
        const currentDocSelection = extraData.currentDocSelection;

        const [returnedState, newUndoRedoState] = undoRedo.redo(
          undoRedoState,
          {
            changeData: changeData,
            currentDocSelection: currentDocSelection,
          },
        );

        selection.setDocSelection(returnedState.currentDocSelection);

        return {
          syncData,
          editData: {
            ...editData,
            changeData: returnedState.changeData,
          },
          extraData: {
            ...extraData,
            undoRedoState: newUndoRedoState,
            currentDocSelection: returnedState.currentDocSelection,
            newActiveStyles: [],
            newInactiveStyles: [],
          },
        };
      });
    },
  };
}

const weakMemoizedGenerateDocumentFunctions = memoize(generateDocumentFunctions);
function generateDocumentFunctions(dispatchProps: ReturnType<typeof mapDispatchToProps>, currentUser: User, documentId: number, versionId: number, assessmentQuestionSignatureVersionId: number) {
  const documentUserStateId = `${documentId}/${currentUser ? currentUser.id : ""}`;

  const basicFuncs = sharedDocFunctions(dispatchProps, documentId, versionId, documentUserStateId, currentUser);

  return {
    ...basicFuncs,
    loadAssessmentQuestionSignatureVersionPublishVersion: () => dispatchProps.versionActions(assessmentQuestionSignatureVersionId).loadVersion(),
    logEvent: (logType, args = {}) => { if (currentUser) { postDocumentUserLog(documentId, logType, args); } },
    setVersionCoverImage: (newVersionCoverImage: string) => basicFuncs.setEditDataVersion({coverImage: newVersionCoverImage}),
    deleteMedia: (mediaType, mediaName) => {
      basicFuncs.setEditDataWithFunctionDocument((editData, syncData, extraData) => {
        const oldMediaList = ((syncData || {})[mediaType]) || ((editData || {})[mediaType]);
        const newMediaList = oldMediaList.filter((name) => name !== mediaName);

        return {...editData,
          [mediaType]: newMediaList,
        };
      });
      basicFuncs.updateDocument();
    },
    submitExam: (successNotice: string) => {
      fetch(full_url(`/documents/${documentId}/end_exam`), {
        method: 'PUT',
        headers: ReactOnRails.authenticityHeaders({
          'Accept': "application/json",
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({})
      })
      .then(response => {
        if (response.ok) {
          alert(successNotice);
          window.location.replace(full_url("/"));
        } else {
          console.log(response.statusText);
          console.log(response.status);
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    pauseExam: () => {
      alert("Your exam has been paused. If you are ready to re-start your exam, please contact your proctor.");
      window.location.replace(full_url("/"));
    },
  };
}

const weakMemoizedGenerateReusableObjectFunctions = memoize(generateReusableObjectFunctions);
function generateReusableObjectFunctions(dispatchProps: ReturnType<typeof mapDispatchToProps>, currentUser: User, documentId: number, versionId: number) {
  const documentUserStateId = `TEMP_ID`; // TODO

  const basicFuncs = sharedDocFunctions(dispatchProps, documentId, versionId, documentUserStateId, currentUser);

  return {
    ...basicFuncs,
    logEvent: (logType, args = {}) => { console.log("No logging for Reusable Content") },
    deleteMedia: (mediaType, mediaName: string) => {
      console.log("Media can not be deleted from Reusable Content");
    },
    updateRoName: (newName: string) => {
      basicFuncs.setEditDataDocument({name: newName});
      basicFuncs.updateDocument();
    },
  };
}

const mergeProps = (stateProps: ReturnType<typeof mapStateToProps>, dispatchProps: ReturnType<typeof mapDispatchToProps>, ownProps: OwnProps) => {
  const {
    docType,
    currentUser,
    documentId,
    assessmentQuestionSignatureVersionId,
  } = ownProps;

  const {
    selectedVersionId,
  } = stateProps;

  let objectFunctions =
    docType === "ReusableObject" ?
      weakMemoizedGenerateReusableObjectFunctions(dispatchProps, currentUser, documentId, selectedVersionId) :
      weakMemoizedGenerateDocumentFunctions(dispatchProps, currentUser, documentId, selectedVersionId, assessmentQuestionSignatureVersionId)

  let compiledProps: NewEditDocContainerProps = {
    ...ownProps,
    ...stateProps,
    ...objectFunctions,
    save: () => {
      // if (documentId) {
      //   const learningObjectives = Array.from(document.querySelectorAll('.learning_objectives_card [class^="doc-list-item"]'));
      //   const hash = {};
      //   learningObjectives.forEach((item) => {
      //     if (item instanceof HTMLElement) {
      //       const uid = item.dataset.uid;
      //       const text = item.textContent;
      //       hash[uid] = text;
      //     }
      //   });

      //   axios.post(full_url(`/documents/${documentId}/create_learning_objectives`), {
      //     learning_objectives: hashstateProps
      //   });
      // }

      if (stateProps.versionEditData?.changeData || stateProps.versionEditData?.coverImage) {
        let newData = weakMemoizedApplyAllChanges(stateProps.version.changeData, changesDisplayModes.HIGHLIGHT_SUGGESTIONS);

        newData = R.clone(newData);
        newData = DocConvertHTML.convertAllInlineNodesToHTML(newData, false);
        newData = stripExtraChangeDataFields(newData);

        objectFunctions.setEditDataVersion({data: newData});
        objectFunctions.updateVersion();
      }

      stateProps.variants.forEach((variant) => {
        if (variant.editData?.changeData) {
          let newData = weakMemoizedApplyAllChanges(variant.editData.changeData, changesDisplayModes.HIGHLIGHT_SUGGESTIONS);

          newData = R.clone(newData);
          newData = DocConvertHTML.convertAllInlineNodesToHTML(newData, false);
          newData = stripExtraChangeDataFields(newData);

          const variantActions = dispatchProps.variantActionsGenerator(variant.id as number) // TODO: Remove as

          variantActions.setEditDataVariant({data: newData});
          variantActions.updateVariant();
        }
      })
    },
  }

  if (compiledProps.version?.data) {
    if (compiledProps.shouldShowEditor || compiledProps.defaultChangesDisplayMode) {
      const editActionContext = {
        data: stateProps.mergedData,
        variantDataById: stateProps.variantMergedDataById,
        currentMenu: compiledProps.versionExtraData?.currentMenu,
        newActiveStyles: compiledProps.versionExtraData?.newActiveStyles || [],
        newInactiveStyles: compiledProps.versionExtraData?.newInactiveStyles || [],
        setNewActiveStyles: compiledProps.setNewActiveStyles,
        setNewInactiveStyles: compiledProps.setNewInactiveStyles,
        addChanges: compiledProps.addChanges,
        setCurrentDocSelection: compiledProps.setCurrentDocSelection,
        nodeDefinitions: nodeDefinitions,
      };

      compiledProps = {
        ...compiledProps,
        insertAtSelection: (text, options={}) => insertAtSelection(text, editActionContext),
        pasteAtSelection: (dataTransfer, options={}) => pasteAtSelection(dataTransfer, editActionContext),
        returnAtSelection: (options={}) => returnAtSelection(editActionContext),
        deleteAtSelection: (options={}) => deleteAtSelection(editActionContext),
        tabAtSelection: (options={}) => tabAtSelection(editActionContext),
        shiftTabAtSelection: (options={}) => shiftTabAtSelection(editActionContext),
        toggleStyleAtSelection: (style: StyleName) => toggleStyleAtSelection(style, editActionContext),
      }
    }
  }

  return compiledProps;
};

const keyMap = {
  undo: "mod+z",
  redo: "mod+y",
  save: "mod+s",
  modReturn: "mod+enter",
  return: "enter",
  delete: ["del", "backspace"],
  tab: "tab",
  shiftTab: "shift+tab",
  bold: "mod+b",
  italic: "mod+i",
  underline: "mod+u",
  superscript: "mod+shift+=",
  subscript: "mod+=",
  find: "mod+f",
};

interface NewEditDocContainerProps extends OwnProps {
  addChanges: (change: Array<Change>) => void;
  addStateDocumentUserState: (state: UserStateStripped, callback?: () => void) => void;
  assessmentQuestion?: AssessmentQuestion;
  assessmentQuestionEditingData?: AssessmentQuestionEditingData;
  assessmentQuestionSignatureVersion?: Version;
  assessmentQuestionSignatureVersionId?: number;
  assessmentQuestionStatistics?: Array<AssessmentStatistic>;
  assessmentStatus?: string;
  assessmentQuestionClinicalLocationOptions?: Array<string>;
  assessmentQuestionFinalDiagnosisOptions?: Array<string>;
  assessmentQuestionPatientAgeOptions?: Array<string>;
  assessmentQuestionClinicalDisciplineOptions?: Array<string>;
  assessmentQuestionClinicalExcellenceOptions?: Array<string>;
  assessmentQuestionSystemOptions?: Array<string>;
  assessmentQuestionClinicalFocusOptions?: Array<string>;
  assessmentQuestionQuestionUseOptions?: Array<string>;
  cciStatusOptions?: Array<CciStatusOptions>;
  cancelEditVersion: () => void;
  changesDisplayMode?: ChangesDisplayMode;
  changeUploadCountForMediaTypeByValue: (type: string, uploadCountChange: number) => void;
  clearCurrentDocSelection: () => void;
  closeCurrentMenu: () => void;
  deleteAtSelection?: () => void;
  deleteMedia: (mediaType: string, mediaName: string) => void;
  destroyDocumentUserState: () => void;
  document: Document;
  documentSyncMeta: ItemSyncMeta;
  documentUserState: DocumentUserState;
  getUploadCountByMediaType: (type: string) => number;
  insertAtSelection?: (data: string) => void;
  loadAssessmentQuestionSignatureVersionPublishVersion?: () => void;
  loadDocument: () => void;
  loadDocumentUserState: () => void;
  loadIfNeededDocumentUserState: () => void;
  loadVersion: () => void;
  logEvent: (logType: string, args?: Record<string, any>) => void;
  mergedData?: MergedData;
  panelNodeEditEnd: (uid: string) => void;
  panelNodeEditStart: (uid: string) => void;
  pasteAtSelection?: (dataTransfer: DataTransfer) => void;
  pauseExam?: () => void;
  reasoningToolUi: ReasoningToolUi;
  redo: () => void;
  returnAtSelection?: () => void;
  save: () => void;
  setChangesDisplayMode: (mode: ChangesDisplayMode) => void;
  setChangeSummaries: (changeSummaries: Array<OffsetChangeSummary>) => void;
  setCurrentDocSelection: (selection: DocSelection) => void;
  setCurrentMenu: (menu: DocMenuName, data?: Record<string, any>) => void;
  setDisplayMode: (displayMode: DisplayMode) => void;
  setEditDataDocumentUserState: (documentUserState: Partial<DocumentUserState>) => void;
  setExtraDataVersion: (extraData: VersionExtraData) => void;
  setFindText: (findText: string) => void;
  setFocusedBookmarkUid: (uid?: string | null) => void;
  setIsMouseDown: (isMouseDown: boolean) => void;
  setLocalClipboard: (data: LocalClipboardData) => void;
  setNewActiveStyles: (newActiveStyles: Array<StyleName>) => void;
  setNewInactiveStyles: (newInactiveStyles: Array<StyleName>) => void;
  setReplaceText: (replaceText: string) => void;
  setTrackChangesEditMode: (trackChangesEditMode: TrackChangesEditMode) => void;
  setVersionCoverImage?: (coverImage: string) => void;
  setVersionId: (versionId: number) => void;
  shiftTabAtSelection?: () => boolean;
  startEditDocument: () => void;
  startEditVersion: () => void;
  startNewDocumentUserState: () => void;
  submitExam?: (successNotice: string) => void;
  tabAtSelection?: () => boolean;
  togglePanel: (panel: PanelType) => void;
  togglePanelDeleting: () => void;
  toggleStyleAtSelection?: (style: StyleName) => boolean;
  undo: () => void;
  unsavedChanges: boolean;
  updateRoName?: (newName: string) => void;
  version: Version;
  versionEditData: VersionEditData;
  versionExtraData: VersionExtraData;
  versionSyncMeta: ItemSyncMeta;
  selectedVersionId: number;
  localLearningObjectives: Array<ReducerItemWithMerged<LearningObjective, LearningObjectiveExtraData>>;
  localLearningObjectiveVariants: Array<ReducerItemWithMerged<Variant, VariantExtraData>>;
  learningObjectiveActionsGenerator: typeof generateLearningObjectiveActions;
  variantActionsGenerator: typeof generateVariantActions;
  variants: Array<ReducerItemWithMerged<Variant, VariantExtraData>>;
  learningObjectives: Array<ReducerItemWithMerged<LearningObjective, LearningObjectiveExtraData>>;
  setAllowLearningObjectivesEdit: (allowLearningObjectivesEdit: string) => void;
  allLearningObjectiveOptions?: Array<{id: number, name: string, documents: Array<{id: number, title: string}>}>;
};

interface NewEditDocContainerState {
  alreadyScrolled: boolean;
  isOnline: boolean;
  autoSaveInterval?: ReturnType<typeof setTimeout>;
  defaultCurrectRLO?: DocSectionType<InlineNode>;

  lockState?: LockState;
  activeEditors: Array<PressenceEditor>;
  versionChannelSubscription?: ActionCable.Subscription & { lockIfFree: () => void; unlockIfOwned: () => void; requestLock: () => void; forceLock: () => void; }
};

class NewEditDocContainer extends React.Component<NewEditDocContainerProps, NewEditDocContainerState> {
  browserName: string;
  beforeInputObserver?: BeforeInputObserver;
  activeUserObserver?: ActiveUserObserver;
  copyObserver?: CopyObserver;
  mouseClickObserver?: MouseClickObserver;
  offlineObserver?: OfflineObserver;
  selectionObserver?: SelectionObserver;
  lastActiveAt?: number;
  loadIfNeededDocumentUserStateTimer?: ReturnType<typeof setTimeout>;
  versionChannelCheckInTimer?: ReturnType<typeof setInterval>;

  constructor(props: NewEditDocContainerProps) {
    super(props);

    const browser = Bowser.getParser(window.navigator.userAgent);
    this.browserName = browser.getBrowserName();

    this.state = {
      alreadyScrolled: false,
      isOnline: navigator.onLine,
      activeEditors: [],
    };
  }

  loadResourcesIfNeeded() {
    if (!this.props.versionSyncMeta) {
      this.props.loadVersion();

      if (this.props.shouldShowEditor) {
        this.props.startEditVersion();
      }
    }

    if (!this.props.documentSyncMeta) {
      this.props.loadDocument();

      if (this.props.shouldShowEditor) {
        this.props.startEditDocument();
      }
    }

    if (this.props.learningObjectives) {
      this.props.learningObjectives.forEach((learningObjective) => {
        if (!learningObjective.syncMeta) {
          const learningObjectiveActions = this.props.learningObjectiveActionsGenerator(learningObjective.id)
          learningObjectiveActions.loadLearningObjective();
        }
      })
    }

    if (this.props.variants) {
      this.props.variants.forEach((variant) => {
        if (!variant.syncMeta) {
          const variantActions = this.props.variantActionsGenerator(variant.id)
          variantActions.loadVariant();

          if (this.props.shouldShowEditor) {
            variantActions.startEditVariant();
          }
        }
      })
    }
  }

  componentDidMount() {
    this.beforeInputObserver = new BeforeInputObserver();
    this.beforeInputObserver.registerDelegate(this);

    this.copyObserver = new CopyObserver();
    this.copyObserver.registerDelegate(this);

    this.offlineObserver = new OfflineObserver();
    this.offlineObserver.registerDelegate(this);

    if (this.props.shouldShowEditor) {
      this.toggleAutoSave();

      this.createVersionChannelSubscription();

      this.selectionObserver = new SelectionObserver();
      this.selectionObserver.registerDelegate(this);

      this.mouseClickObserver = new MouseClickObserver();
      this.mouseClickObserver.registerDelegate(this);

      this.activeUserObserver = new ActiveUserObserver();
      this.activeUserObserver.registerDelegate(this);
      this.lastActiveAt = Date.now();
    }

    this.loadResourcesIfNeeded();

    if (this.props.currentUser) {
      if (this.props.docType === "ReusableObject") {
        this.props.startNewDocumentUserState();
        this.props.setEditDataDocumentUserState({id: "TEMP_ID" as any, state: {}}); // TODO: REMOVE ANY
      } else {
        this.props.loadDocumentUserState();
        this.loadIfNeededDocumentUserStateTimer = setInterval(this.props.loadIfNeededDocumentUserState, 5000);
      }
    }

    if (this.props.assessmentQuestionSignatureVersionId) {
      this.props.loadAssessmentQuestionSignatureVersionPublishVersion();
    }

    if (Array.isArray(this.props.scrollable_uids) && this.props.scrollable_uids[0]) {
      this.props.setExtraDataVersion({
        displayMode: displayModes.FULL,
      });
    }
  }

  createVersionChannelSubscription() {
    const {
      selectedVersionId,
      currentUser,
      sessionId,
      cableApp,
    } = this.props

    const consumer = cableApp?.cable

    if (consumer) {
      const container = this
      const versionChannelSubscription = consumer.subscriptions.create({
        channel: "VersionChannel",
        version_id: selectedVersionId,
        session_id: sessionId,
      }, {
        connected() {
          console.log("Connected from VersionChannel");

          if (!container.versionChannelCheckInTimer) {
            this.checkIn()

            container.versionChannelCheckInTimer = setInterval(this.checkIn.bind(this), 10000);
          }
        },
        disconnected() {
          console.log("Disconnected from VersionChannel");
        },
        received(data) {
          const {
            activeEditors,
            lockSessionId,
            lockUserId,
            lockUserSessionId,
            lockRequestSessionId,
            lockRequestUserId,
            lockRequestUserSessionId,
          } = data;

          const {
            lockState,
          } = container.state;

          const hadLock = currentUser.id === lockState?.lockUserId && sessionId === lockState?.lockSessionId;
          const hasLock = currentUser.id === data.lockUserId && sessionId === data.lockSessionId;

          container.setState({
            activeEditors,
            lockState: {
              hasLock,
              lockSessionId,
              lockUserId,
              lockUserSessionId,
              lockRequestSessionId,
              lockRequestUserId,
              lockRequestUserSessionId,
            },
          })

          if (hadLock !== hasLock) {
            if (hasLock) {
              container.props.loadVersion();
              container.props.startEditVersion();
            } else {
              container.props.cancelEditVersion();
            }
          }
        },
        checkIn() {
          versionChannelSubscription.perform('check_in', {last_active: Math.round(container.lastActiveAt/1000)});
        },
        lockIfFree() {
          versionChannelSubscription.perform('lock_if_free');
        },
        unlockIfOwned() {
          versionChannelSubscription.perform('unlock_if_owned');
        },
        requestLock() {
          versionChannelSubscription.perform('request_lock');
        },
        forceLock() {
          versionChannelSubscription.perform('force_lock');
        },
      })

      this.setState({versionChannelSubscription: versionChannelSubscription});

    }
  }

  unsubscribeVersionChannelSubscription() {
    const {
      versionChannelSubscription,
    } = this.state;

    if (versionChannelSubscription) {
      versionChannelSubscription.unsubscribe();
      this.setState({versionChannelSubscription: null});
    }

    if (this.versionChannelCheckInTimer) {
      clearTimeout(this.versionChannelCheckInTimer);
    }
  }

  lockIfFree() {
    const {
      versionChannelSubscription,
    } = this.state;

    versionChannelSubscription.lockIfFree();
  }

  unlockIfOwned() {
    const {
      versionChannelSubscription,
    } = this.state;

    const shouldUnlock = confirm("Are you sure you would like to release the editing lock? Any unsaved changes may be lost.");
    if (shouldUnlock) {
      versionChannelSubscription?.unlockIfOwned();
    }
  }

  forceLock() {
    const {
      versionChannelSubscription,
    } = this.state;

    const shouldForceLock = confirm("Are you sure you would like to take the editing lock? Any unsaved changes by the current lock holder may be lost.");
    if (shouldForceLock) {
      versionChannelSubscription?.forceLock();
    }
  }

  getSnapshotBeforeUpdate(prevProps: NewEditDocContainerProps, prevState: NewEditDocContainerState) {
    return {
      beforeUpdateDocSelection: selection.getDocSelection()
    }
  }

  componentDidUpdate(prevProps: NewEditDocContainerProps, prevState: NewEditDocContainerState, snapshot: {beforeUpdateDocSelection: DocSelection}) {
    this.syncDOMSelection(snapshot.beforeUpdateDocSelection);

    if (!prevState.alreadyScrolled && Array.isArray(prevProps.scrollable_uids)) {
      const element = scrollToElementWithUid(prevProps.scrollable_uids);
      if (element) {
        this.props.setFocusedBookmarkUid(prevProps.scrollable_uids[0])
        this.setState({alreadyScrolled: true});
      }
    }

    this.loadResourcesIfNeeded();

    if (this.props.shouldShowEditor && prevProps.selectedVersionId !== this.props.selectedVersionId) {
      this.unsubscribeVersionChannelSubscription();
      this.createVersionChannelSubscription();
    }

    if (this.state.isOnline && !prevState.isOnline && !this.props.isEmbeded) {
      alert("Your connection has been restored. Click OK to dismiss this alert and continue your work.");
    }

    const {
      mergedData,
      versionExtraData,
    } = this.props;

    if (this.props.shouldShowEditor && mergedData) {
      const prioritizedOffsetChangeSummary = versionExtraData.changeSummaries?.find((changeSummary) => changeSummary.prioritizeOffset)
      const changeSummaries = computeSpacedChangeSummaries(mergedData, versionExtraData.currentMenu, versionExtraData.currentDocSelection, prioritizedOffsetChangeSummary);

      if (!R.equals(changeSummaries, versionExtraData.changeSummaries)) {
        this.props.setChangeSummaries(changeSummaries);
      }
    }
  }

  componentWillUnmount() {
    this.beforeInputObserver?.unregisterDelegate(this);
    this.copyObserver?.unregisterDelegate(this);

    this.offlineObserver?.unregisterDelegate(this);

    if ( this.loadIfNeededDocumentUserStateTimer ) { clearInterval(this.loadIfNeededDocumentUserStateTimer); }

    if ( this.state.autoSaveInterval ) { clearInterval(this.state.autoSaveInterval); }

    this.selectionObserver?.unregisterDelegate(this);
    this.mouseClickObserver?.unregisterDelegate(this);
    this.activeUserObserver?.unregisterDelegate(this);
  }

  checkMappedContentEditMode(currentDocSelection: DocSelection) {
    const currentlyEditingNodeLineage = findNodeLineageByUid(this.props.mergedData, currentDocSelection.start.uid);

    let activeLearningObjective = null
    if (currentlyEditingNodeLineage) {
      let activeLoId = currentlyEditingNodeLineage.find( node => node.type === "learningObjective")?.uid
      activeLearningObjective = this.props.mappingData.learningObjectives.find( lo => lo.id === activeLoId)
    }

    let reusableObjectIds = this.props.mappingData.learningObjectiveReusableObjects.map(loro => loro.reusable_object_id)
    let proposedReusableObjectIds = this.props.mergedData.proposedLearningObjectiveReusableObjects ? this.props.mergedData.proposedLearningObjectiveReusableObjects.map(loro => loro.reusable_object_id) : []
    let totalReusableObjectIds=Array.from(new Set(reusableObjectIds.concat(proposedReusableObjectIds)))
    let reusableObjectUids = this.props.mergedData.proposedLearningObjectiveReusableObjects ? totalReusableObjectIds.map(id => this.props.mappingData.reusableObjects.find(ro => ro.id === id)?.uid) : []

    let currentlyEditingNodeLineageUids = currentlyEditingNodeLineage.map(obj => obj.uid)
    let checkEditingContentIsMappedLO = activeLearningObjective && this.props.mappingData.learningObjectiveReusableObjects.find(loro => loro.learning_objective_id === activeLearningObjective.id) !== undefined
    let checkEditingContentIsProposeddLO = activeLearningObjective && this.props.mergedData.proposedLearningObjectiveReusableObjects && this.props.mergedData.proposedLearningObjectiveReusableObjects.find(loro => loro.learning_objective_id === activeLearningObjective.id) !== undefined
    let checkEditingContentIsMappedTP = reusableObjectUids && currentlyEditingNodeLineageUids.filter(element => reusableObjectUids.indexOf(element) !== -1).length!==0

    let isMultiUseRO = false
    if (currentlyEditingNodeLineage) {
      const roDocumentCount = currentlyEditingNodeLineage.find(node => node.roDocumentCount)
      isMultiUseRO = roDocumentCount && roDocumentCount.roDocumentCount > 1
    }

    if(checkEditingContentIsMappedLO || checkEditingContentIsProposeddLO || checkEditingContentIsMappedTP) {
      alert("Editing of mapped content is permitted in Suggesting mode only.");
      this.props.setTrackChangesEditMode("SUGGESTING_MODE")
    } else if(isMultiUseRO) {
      alert("Editing of multi-use Reusable Content is permitted in Suggesting mode only.");
      this.props.setTrackChangesEditMode("SUGGESTING_MODE")
    }
  }

  beforeInputDidFire(event: InputEvent) {
    const currentDocSelection = selection.getDocSelection();

    if (!currentDocSelection) { return false; }

    event.preventDefault();

    let allowedMode = this.props.versionExtraData.trackChangesEditMode || (this.props.permissions.allowedEditMode && `${this.props.permissions.allowedEditMode.toUpperCase()}_MODE`) || trackChangesEditModes.EDITING_MODE
    if (allowedMode === trackChangesEditModes.EDITING_MODE) {
      if (!this.props.isAdmin && this.props.docType !== "ReusableObject") {
        this.checkMappedContentEditMode(currentDocSelection);
      }
    }

    switch (event.inputType) {
      case "insertText":
        return this.props.insertAtSelection(event.data);
      case "insertFromPaste":
      case "insertFromYank":
      case "insertFromDrop":
        return this.props.pasteAtSelection(event.dataTransfer);
      case "insertLineBreak":
      case "insertParagraph":
        return this.props.returnAtSelection();
      case "deleteByCut":
        this.addSelectionToCacheIfValid();
      case "deleteByDrag":
      case "deleteContent":
      case "deleteContentBackward":
      case "deleteEntireSoftLine":
      case "deleteHardLineBackward":
      case "deleteSoftLineBackward":
      case "deleteWordBackward":
        return this.props.deleteAtSelection();
      case "historyUndo":
        return this.props.undo();
      case "historyRedo":
        return this.props.redo();
      case "insertCompositionText":
      case "insertFromComposition":
      case "insertReplacementText":
      case "insertOrderedList":
      case "insertUnorderedList":
      case "insertHorizontalRule":
      case "insertFromPasteAsQuotation":
      case "insertTranspose":
      case "insertLink":
      case "deleteByComposition":
      case "deleteCompositionText":
      case "deleteWordForward":
      case "deleteSoftLineForward":
      case "deleteHardLineForward":
      case "deleteContentForward":
      case "formatBold":
      case "formatItalic":
      case "formatUnderline":
      case "formatStrikeThrough":
      case "formatSuperscript":
      case "formatSubscript":
      case "formatJustifyFull":
      case "formatJustifyCenter":
      case "formatJustifyRight":
      case "formatJustifyLeft":
      case "formatIndent":
      case "formatOutdent":
      case "formatRemove":
      case "formatSetBlockTextDirection":
      case "formatSetInlineTextDirection":
      case "formatBackColor":
      case "formatFontColor":
      case "formatFontName":
      default:
        console.log("Uncaught InputEvent");
    }
  }

  copyDidFire(event: ClipboardEvent) {
    this.addSelectionToCacheIfValid();
  }

  activeUserDidFire(event: MouseEvent | KeyboardEvent) {
    this.lastActiveAt = Date.now();
  }

  addSelectionToCacheIfValid() {
    const {
      version,
      versionExtraData,
    } = this.props;

    const currentMenu = versionExtraData?.currentMenu;
    let currentDocSelection = selection.getDocSelection();

    if (!currentDocSelection || currentMenu) { return false; }

    let changeData = version.changeData;
    if (!changeData || R.isEmpty(changeData)) {
      changeData = weakMemoizedConvertAllToFlatFlowAndInlineRangeJSON(version.data);
    }

    currentDocSelection = adjustSelectionStartToIncludeListItemIfNeeded(changeData, currentDocSelection)

    const translatedSelection = translateSelectionToFlowRange(changeData, currentDocSelection);

    if (!translatedSelection) { return false; }

    const foundFlatFlowNodes = findFlatFlowNodesWithFlowRange(changeData, translatedSelection);

    if (!foundFlatFlowNodes || foundFlatFlowNodes.nodes.length === 0) { return false; }

    const newClipboardNodes = flatFlowToTreeFlow(foundFlatFlowNodes.nodes, foundFlatFlowNodes.listNodes, changesDisplayModes.HIGHLIGHT_SUGGESTIONS);

    this.props.setLocalClipboard(newClipboardNodes);
  }

  didGoOffline() { if(this.state.isOnline) { this.setState({isOnline: false}); }; }
  didGoOnline() { if(!this.state.isOnline) { this.setState({isOnline: true}); }; }

  mouseDidDownClick(event: MouseEvent) { this.props.setIsMouseDown(true); }
  mouseDidUpClick(event: MouseEvent) { this.props.setIsMouseDown(false); }

  selectionDidChange(event: Event) { this.syncCurrentDocSelection(); }

  syncCurrentDocSelection() {
    const {
      closeCurrentMenu,
      setExtraDataVersion,
      versionExtraData,
    } = this.props;

    const currentDocSelection = versionExtraData.currentDocSelection;
    const newDocSelection = selection.getDocSelection();

    const currentMenu = versionExtraData.currentMenu;

    if (newDocSelection) {
      if (!selection.areDocSelectionsEqual(currentDocSelection, newDocSelection)) {
        if ([NEW_SUMMARY_OF_EDITS, NEW_COMMENT_THREAD, EDIT_COMMENT, EDIT_LINK, TEXT_COLOR_PICKER, ADD_NODE].includes(currentMenu?.menu)) {
          closeCurrentMenu();
        }

        // console.log("syncCurrentDocSelection:", newDocSelection);
        setExtraDataVersion({
          currentDocSelection: newDocSelection,
          newActiveStyles: [],
          newInactiveStyles: [],
        });
      }
    }
  }

  syncDOMSelection(beforeUpdateDocSelection: DocSelection) {
    const {
      versionExtraData,
    } = this.props;

    let newDocumentSelection = beforeUpdateDocSelection;

    if (window.forceDocSelection) {
      newDocumentSelection = window.forceDocSelection
      window.forceDocSelection = undefined;
    }

    const showingDOMRange = selection.getDOMRange();
    const newDOMRange = selection.docSelectionToDOMRange(newDocumentSelection);

    if (!selection.areDOMRangesEqual(showingDOMRange, newDOMRange)) {
      const currentMenu = versionExtraData?.currentMenu;
      if (![NEW_SUMMARY_OF_EDITS, NEW_COMMENT_THREAD, EDIT_COMMENT, EDIT_LINK, TEXT_COLOR_PICKER].includes(currentMenu?.menu)) {
        // console.log("syncDOMSelection", newDocumentSelection);
        selection.setDocSelection(newDocumentSelection);
      }
    }
  }

  toggleAutoSave() {
    if (this.state.autoSaveInterval) {
      clearInterval(this.state.autoSaveInterval);
      this.setState({
        autoSaveInterval: undefined,
      });
    } else {
      this.setState({
        autoSaveInterval: setInterval(this.autoSave.bind(this), 30000),
      });
    }
  }

  autoSave() {
    if (this.props.unsavedChanges) {
      this.props.save();
    }
  }

  changesToMappedContent(mergedData) {
    let learningObjectiveIds = this.props.mappingData.learningObjectiveReusableObjects.map( loro => loro.learning_objective_id)

    let learningObjectiveUids = learningObjectiveIds.map(id => this.props.mappingData.learningObjectives.find(lo => lo.id === id)?.uid)
    let loNodes = learningObjectiveUids.map(uid => findNodeByUid(mergedData, uid))
    let validLoNodes = loNodes.filter(n => n)
    let allLoChanges = validLoNodes.map(node => node.content[0].content?.addDeleteChanges?.concat(node.uid))

    let reusableObjectIds = this.props.mappingData.learningObjectiveReusableObjects.map( loro => loro.reusable_object_id)
    let reusableObjectUids = reusableObjectIds.map(id => this.props.mappingData.reusableObjects.find(ro => ro.id === id)?.uid)
    let roNodes = reusableObjectUids.map(uid => findNodeByUid(mergedData, uid))
    let validRoNodes = roNodes.filter(n => n)
    let roTitleChanges = validRoNodes.map(node => node.title?.addDeleteChanges).filter(change => change)
    let roContentChanges = validRoNodes.map(node => node.content[0].content?.addDeleteChanges).filter(change => change)
    let unpublishedLoChanges = allLoChanges.filter(change => change).filter(change => change[change?.length - 2]?.published_at > ((new Date()).getTime() - 500))
    let unpublishedRoTitleChanges = roTitleChanges.filter(change => change[change?.length - 1]?.published_at > ((new Date()).getTime() - 500 ))
    let unpublishedRoContentChanges = roContentChanges.filter(change => change[change?.length - 1]?.published_at > ((new Date()).getTime() - 500))
    let allUnpublishedChanges = unpublishedLoChanges.concat(unpublishedRoTitleChanges).concat(unpublishedRoContentChanges)

    return allUnpublishedChanges
  }

  publishMergedData() {
    const {
      currentUser,
      document,
      version,
      variants,
      localLearningObjectives,
      docType,
    } = this.props;

    const oldChangeData = generateChangeData(version);

    const publishChange = clotheChange(newPublishChange(), currentUser, false);
    let mergedData = addChangeToDoc(oldChangeData, publishChange);

    mergedData = weakMemoizedApplyAllChanges(mergedData, changesDisplayModes.HIDE_SUGGESTIONS);

    const variantPlaceholderPaths = mergedData ? findVariantPlaceholderNodePaths(mergedData) : []
    const learningObjectivePlaceholderPaths = variantPlaceholderPaths.filter((vpPath) => findAtPath(mergedData, vpPath).variantableType === "LearningObjective").filter((path) => !path.includes("addDeleteChanges"))
    const learningObjectiveNodePathsById = Object.fromEntries(learningObjectivePlaceholderPaths.map((loPlaceholderPath) => [findAtPath(mergedData, loPlaceholderPath).variantableId, loPlaceholderPath]))

    if (document) {
      const versionType = determineVersionType(document as any, version.id); // TODO: Remove any
      const variantType = determineVariantTypeFromVersionType(versionType);
      const variantKey = variantKeyFromVariantVersion(variantType);

      variants.forEach((learningObjectiveVariant) => {
        if (learningObjectiveVariant?.mergedData) {
          const parentLearningObjective = localLearningObjectives.find((lo) => lo?.mergedData?.[variantKey] === learningObjectiveVariant.id)
          const learningObjectiveNodePath = learningObjectiveNodePathsById[parentLearningObjective.id]

          if (learningObjectiveNodePath) {
            const appliedData = applyChangesToVariant(learningObjectiveVariant.mergedData, parentLearningObjective?.mergedData?.publishVariantId, changesDisplayModes.HIDE_SUGGESTIONS, true)

            mergedData = setAtPath(mergedData, learningObjectiveNodePath, appliedData)
            mergedData = setAtPath(mergedData, learningObjectiveNodePath.concat(['variantId']), learningObjectiveVariant.id)
          }
        }
      })
    }

    if (docType !== "ReusableObject") {
      mergedData = weakMemoizedReflow(mergedData, document.kind, document.id);
    }

    return R.clone(mergedData);
  }

  publishWithoutWarning(shouldUpdateBanner: boolean){
    const {
      document,
      version,
      addChanges,
      save,
      undo,
      docType,
    } = this.props;

    const mergedData = this.publishMergedData();

    let allHTMLData = DocConvertHTML.convertAllInlineNodesToHTML(mergedData) as DocBlockNode<InlineHtmlNode>; // TODO: Remove as
    allHTMLData = stripExtraChangeDataFields(allHTMLData) as DocBlockNode<InlineHtmlNode>; // TODO: Remove as

    let changedMappedContent = [];
    if(document?.kind !== "assessment_question_mcq" && docType !== "ReusableObject") {
      changedMappedContent = this.changesToMappedContent(mergedData)
    }

    if(changedMappedContent.length > 0 && !confirm("There are changes to mapped content! Click OK to continue publishing or Cancel to review changes.")) {
      return;
    }

    let publishUrl, publishData
    if (docType === "ReusableObject") {
      publishUrl = full_url(`/variants/${version.id}/publish`);
      publishData = {
        variant: {}
      }
    } else {
      publishUrl = full_url(`/documents/${document.id}/publish`);
      publishData = {
        document: {
          should_update_banner: shouldUpdateBanner,
          data: allHTMLData,
          cover_image: version.coverImage,
          mod_map_image_filename: version.modMapImageFilename,
        },
      }
    }

    axios({
      method: "post",
      withCredentials: true,
      url: publishUrl,
      headers: ReactOnRails.authenticityHeaders({Accept: "application/json"}),
      data: publishData,
    })
    .then((response) => {
      window.customAlertBox({title: "Publishing!", message: "This version is being published"});
      addChanges([newPublishChange()]);
      save();
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    })
    .catch((error) => {
      window.customAlertBox({title: "Publishing Error!", message: "There was an error publishing."});
      undo();
      throw (error);
    });
  }

  publish() {
    const {
      document,
      docType,
    } = this.props;

    const mergedData = this.publishMergedData();

    if(document?.kind === "assessment_question_mcq" && (!mergedData.proposedLearningObjectiveReusableObjectId)) {
      window.customAlertBox({title: "Publishing Error!", message: "The document can not be published because there are incomplete mappings"});
      return
    }

    window.customConfirmBox({
      title: "Please Confirm!",
      message: "Are you sure you want to publish this case? Remember to update the SR Version with these edits!'",
      okLabel: "Publish",
      callback: () => {
        if (docType === "ReusableObject" || document?.kind === "assessment_question_mcq") {
          this.publishWithoutWarning(false);
        } else {
          window.customConfirmBox({
            title: "Update Banner",
            message: "Would you like to show the 'Update Banner?'",
            callback: () => this.publishWithoutWarning(true),
            cancel: () => this.publishWithoutWarning(false),
          })
        }
      },
    });
  }

  render() {
    const {
      docType,
      documentSetId,
      document,
      version,
      assessmentQuestion,
      assessmentQuestionEditingData,
      assessmentQuestionStatistics,
      assessmentQuestionSignatureVersion,
      assessmentQuestionSignatureVersionId,
      assessmentQuestionClinicalLocationOptions,
      assessmentQuestionFinalDiagnosisOptions,
      assessmentQuestionPatientAgeOptions,
      assessmentQuestionClinicalDisciplineOptions,
      assessmentQuestionClinicalExcellenceOptions,
      assessmentQuestionSystemOptions,
      assessmentQuestionClinicalFocusOptions,
      assessmentQuestionQuestionUseOptions,
      versionSyncMeta,
      versionEditData,
      versionExtraData,
      currentUser,
      currentGroupRoleDefinition,
      documentUserState,
      isAdmin,
      cciStatusOptions,
      assessmentStatus,
      reasoningToolUi,
      displayUid,
      changesDisplayMode,
      mappingData,
      isEmbeded,
      featureFlag,
      sessionId,
      localLearningObjectives,
      localLearningObjectiveVariants,
      allLearningObjectiveOptions,
    } = this.props;

    let {
      mergedData,
    } = this.props;

    const {
      isOnline,
      activeEditors,
      lockState,
    } = this.state;

    if (document?.id && version?.id && documentUserState?.id) {
      ///////////////////
      // User Context //
      /////////////////
      const userContext = weakMemoizedGenerateCurrentUser(
        currentUser || {} as User, // currentUser
        currentGroupRoleDefinition, // currentGroupRoleDefinition
        isAdmin, // isAdmin
        sessionId, // sessionId
        this.props.permissions,
        this.props.loadDocumentUserState, // loadDocumentUserState
        this.props.addStateDocumentUserState, // addStateDocumentUserState
        this.props.destroyDocumentUserState, // destroyDocumentUserState
        this.props.submitExam, // submitExam
        this.props.pauseExam, // pauseExam
        this.props.logEvent, //logEvent
        version.data.uid, // rootUID
      );

      //////////////////////
      // DISPLAY CONTEXT //
      ////////////////////
      const displayMode = versionExtraData.displayMode || (this.props.shouldShowEditor ? displayModes.FULL : (this.props.isNotGated ? displayModes.UNGATED : displayModes.STUDENT));
      const displayContext = weakMemoizedGenerateDisplayContext(
        displayMode, // displayMode
        this.props.setDisplayMode, // setDisplayMode
        isOnline, // isOnline
        versionExtraData.focusedBookmarkUid, // focusedBookmarkUid
        this.props.setFocusedBookmarkUid, // setFocusedBookmarkUid
        this.props.togglePanel, // togglePanel
        this.props.panelNodeEditStart, // panelNodeEditStart
        this.props.isEmbeded, // isEmbeded
        this.props.enableAiSummaryStatementFeedback, // enableAiSummaryStatementFeedback
        !lockState?.hasLock, // isLockedByOtherUser
      );

      ///////////////////////
      // DOCUMENT CONTEXT //
      /////////////////////
      const documentContext = weakMemoizedGenerateCurrentDocument(
        document,
        docType,
        this.props.loadDocument, // reload
      );

      ///////////////////////
      // MEDIA CONTEXT //
      /////////////////////
      const mediaContext = organizeMediaAssets(document);

      ////////////////////////////
      // VERSION WITHOUT STATE //
      //////////////////////////
      if (this.props.shouldShowEditor && version.id !== publishVersionId(documentContext)) {
        // Required because nested contentEditables in Safari allow selection from parent into child
        if (this.browserName === "Safari" && versionExtraData.currentDocSelection && versionExtraData.isMouseDown) {
          mergedData = weakMemoizedSetBlockNodesContainsSelection(mergedData, versionExtraData.currentDocSelection);
        }

        if (versionExtraData.currentMenu) {
          mergedData = weakMemoizedAttachCurrentMenuToNode(mergedData, versionExtraData.currentMenu, versionExtraData.localClipboard);

          let currentDocSelection = versionExtraData.currentDocSelection

          if (
            [NEW_SUMMARY_OF_EDITS, NEW_COMMENT_THREAD, EDIT_COMMENT, EDIT_LINK, TEXT_COLOR_PICKER].includes(versionExtraData.currentMenu?.menu) &&
            currentDocSelection &&
            selection.isInOneInline(currentDocSelection) &&
            currentDocSelection.start.contentKey
          ) {
            const nodePath = findNodePathByUid(mergedData, currentDocSelection.start.uid)

            mergedData = R.set(
              R.lensPath([...nodePath, currentDocSelection.start.contentKey, "selectionMimics"]),
              [{range: {start: currentDocSelection.start.index, end: currentDocSelection.end.index}}],
              mergedData,
            )
          }
        }

        const shouldReflow = displayContext.displayMode === displayModes.STUDENT;
        if (shouldReflow) {
          mergedData = weakMemoizedReflow(mergedData, document.kind, document.id);
        }
      }

      ////////////////////////////////
      // APPLY DOCUMENT USER STATE //
      //////////////////////////////
      if (displayContext.displayMode !== displayModes.FULL || !this.props.shouldShowEditor) {
        mergedData = addDocumentUserState(mergedData, documentUserState.state);

        const notes = (mergedData.notes || []);
        notes.filter((note) => note.notedNodeUid).forEach((note) => {
          mergedData = editNodeWithUIDKeyValue(mergedData, note.notedNodeUid, "noteUid", note.uid);
        });
      }

      if (displayContext.focusedBookmarkUid) {
        const nodePath = findNodePathByUid(mergedData, displayContext.focusedBookmarkUid);
        if (nodePath) {
          mergedData = setAtPath(mergedData, [...nodePath, "isFocusedBookmark"], true)
        }
      }

      const setCurrentRO = (rlo: DocSectionType<InlineNode>) => {
        this.setState({defaultCurrectRLO: rlo})
      }
      let currentRO = this.state.defaultCurrectRLO;
      const currentActiveDocSelection = selection.getDocSelection();

      if(currentActiveDocSelection){
        const {
          uid: startUID,
        } = currentActiveDocSelection.start;
        const lineage = findNodeLineageByUid(mergedData, startUID);
        if (lineage) {
          currentRO = R.findLast((node): node is DocSectionType<InlineNode> => node["type"] === "section" && node["classes"] && R.intersection(node["classes"], POSSIBLE_RO_CLASSES).length > 0, lineage)
        }
      }

      // THIS SHOULD NOT BE IN RENDER MOVE WHEN SAFE TO DO SO
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('uid')) {
        const uid = urlParams.get('uid');
        mergedData = editNodeWithUIDKeyValue(mergedData, uid, "isFocusedBookmark", true);
        let topHeight = 0;
        const element = document.getElementById(uid);
        if (element !== null) {
          topHeight = element.offsetTop;
        }
        window.scrollTo({
          top: Math.round(topHeight),
          behavior: 'smooth'
        });
      }

      if(currentRO && documentContext.docType !== "ReusableObject" && userContext.permissions.createExistingContentRlo){
        mergedData = editNodeWithUIDKeyValue(mergedData, currentRO.uid, "isFocusedBookmark", true);
      }
      //////////////////////
      // CURRENT VERSION //
      ////////////////////
      const currentVersion = weakMemoizedGenerateCurrentVersion(
        version.id, // id
        version.published, // published
        version.awsFolderUrl, // awsFolderUrl
        version.coverImage, // coverImage
        mergedData.onlyOneUncollapsedSection, // onlyOneUncollapsedSection
        mergedData.disableBookmarks, // disableBookmarks
        this.props.loadVersion, // reload
      );

      ///////////////////
      // EDIT CONTEXT //
      /////////////////
      let editingContext;
      let editingContextExtra: EditingContextExtra;
      if (this.props.shouldShowEditor) {
        editingContext = weakMemoizedGenerateEditingContext(
          this.props.shouldShowEditor, // shouldShowEditor,
          displayContext.canBeEditing, // canBeEditing
          !!versionEditData, // isVersionEditActivated,
          changesDisplayMode, // changesDisplayMode,
          currentVersion.id === publishVersionId(documentContext), // isPublishedVersion
          currentVersion.id === ongoingMaintenaceVersionId(documentContext), // isMaintenanceVersion
          userContext.permissions.accessOmVersion || userContext.permissions.accessRloMaintenance, // canEditOM
          nodeDefinitions, // nodeDefinitions,
          userContext.id,
          version.changeAuthorIds,
          this.props.addChanges, // addChanges
          this.props.setCurrentDocSelection,
          this.props.clearCurrentDocSelection,
          this.props.setCurrentMenu,
          this.props.closeCurrentMenu,
          this.props.setLocalClipboard,
          this.props.editors,
          versionExtraData.allowLearningObjectivesEdit
        );

        const currentDocSelectionDetail: Pick<EditingContextExtra, "currentDocSelection" | "activeStylesFromChanges" | "activeStyles" | "soloSelectedNodeUid" | "currentlyEditingNodeLineage"> = {currentDocSelection: versionExtraData.currentDocSelection};

        const newActiveStyles = versionExtraData.newActiveStyles || [];
        const newInactiveStyles = versionExtraData.newInactiveStyles || [];

        if (currentDocSelectionDetail.currentDocSelection) {
          // console.log(currentDocSelectionDetail.currentDocSelection);

          currentDocSelectionDetail.activeStylesFromChanges = RangeInlineJSON.stylesCoveringDocSelection(mergedData, currentDocSelectionDetail.currentDocSelection) as Record<StyleName, StyleRange>;
          currentDocSelectionDetail.activeStyles = xor(R.keys(currentDocSelectionDetail.activeStylesFromChanges).concat(newActiveStyles), newInactiveStyles);

          if (currentDocSelectionDetail.currentDocSelection.start.uid === currentDocSelectionDetail.currentDocSelection.end.uid) {
            currentDocSelectionDetail.soloSelectedNodeUid = currentDocSelectionDetail.currentDocSelection.start.uid;
            currentDocSelectionDetail.currentlyEditingNodeLineage = findNodeLineageByUid(mergedData, currentDocSelectionDetail.soloSelectedNodeUid);
          }
        }

        const currentMenuDetail: Pick<EditingContextExtra, "currentMenu" | "currentMenuNode"> = {currentMenu: versionExtraData.currentMenu}
        if (currentMenuDetail.currentMenu && 'uid' in currentMenuDetail.currentMenu) {
          currentMenuDetail.currentMenuNode = findNodeByUid(mergedData, currentMenuDetail.currentMenu.uid);
        }

        const allowedMode = userContext.permissions.allowedEditMode && trackChangesEditModes[`${userContext.permissions.allowedEditMode.toUpperCase()}_MODE`]

        editingContextExtra = {
          rootUid: mergedData.uid,
          changesDisplayMode: changesDisplayMode,
          isActiveEditData: editingContext.isVersionEditActivated && this.props.unsavedChanges,
          isSyncing: !!versionSyncMeta.isSyncing,
          savingErrors: versionSyncMeta.errors || [],
          setVersionId: this.props.setVersionId,
          trackChangesEditMode: versionExtraData.trackChangesEditMode || allowedMode || trackChangesEditModes.EDITING_MODE,
          setTrackChangesEditMode: this.props.setTrackChangesEditMode,
          setChangesDisplayMode: this.props.setChangesDisplayMode,
          save: this.props.save,
          isAutoSaveOn: !!this.state.autoSaveInterval,
          toggleAutoSave: this.toggleAutoSave.bind(this),
          publish: this.publish.bind(this),
          currentRO: currentRO,
          undo: this.props.undo,
          redo: this.props.redo,
          setCurrentRO: setCurrentRO,
          canUndo: undoRedo.canUndo(this.props.versionExtraData.undoRedoState),
          canRedo: undoRedo.canRedo(this.props.versionExtraData.undoRedoState),
          deleteMedia: this.props.deleteMedia,
          updateRoName: this.props.updateRoName,
          getUploadCountByMediaType: this.props.getUploadCountByMediaType,
          changeUploadCountForMediaTypeByValue: this.props.changeUploadCountForMediaTypeByValue,
          findText: versionExtraData.findText,
          replaceText: versionExtraData.replaceText,
          setFindText: this.props.setFindText,
          setReplaceText: this.props.setReplaceText,
          setVersionCoverImage: this.props.setVersionCoverImage,
          toggleStyle: this.props.toggleStyleAtSelection,
          newActiveStyles: newActiveStyles,
          newInactiveStyles: newInactiveStyles,
          setNewActiveStyles: this.props.setNewActiveStyles,
          setNewInactiveStyles: this.props.setNewInactiveStyles,
          currentMenu: versionExtraData.currentMenu,
          cci_status: mergedData.cci_status,
          cciStatusOptions: cciStatusOptions,
          assessmentStatus: assessmentStatus,
          assessmentQuestion: mergedData.assessment_question || assessmentQuestion,
          assessmentQuestionEditingData: assessmentQuestionEditingData,
          assessmentQuestionStatistics: assessmentQuestionStatistics,
          assessmentQuestionSignatureVersion: assessmentQuestionSignatureVersion,
          assessmentQuestionSignatureVersionId: assessmentQuestionSignatureVersionId,
          assessmentQuestionClinicalLocationOptions: assessmentQuestionClinicalLocationOptions,
          assessmentQuestionFinalDiagnosisOptions: assessmentQuestionFinalDiagnosisOptions,
          assessmentQuestionPatientAgeOptions: assessmentQuestionPatientAgeOptions,
          assessmentQuestionClinicalDisciplineOptions: assessmentQuestionClinicalDisciplineOptions,
          assessmentQuestionClinicalExcellenceOptions: assessmentQuestionClinicalExcellenceOptions,
          assessmentQuestionSystemOptions: assessmentQuestionSystemOptions,
          assessmentQuestionClinicalFocusOptions: assessmentQuestionClinicalFocusOptions,
          assessmentQuestionQuestionUseOptions: assessmentQuestionQuestionUseOptions,
          usedAvailableForm: this.props.usedAvailableForm,
          currentAssessmentQuestionEditingData: currentDocSelectionDetail.soloSelectedNodeUid && currentDocSelectionDetail.soloSelectedNodeUid === assessmentQuestionEditingData?.learning_objective_uid ? assessmentQuestionEditingData : null,
          possiblePrerequisiteDocuments: this.props.possiblePrerequisiteDocuments,
          activeEditors: activeEditors,
          lockState: lockState,
          lockIfFree: this.lockIfFree.bind(this),
          unlockIfOwned: this.unlockIfOwned.bind(this),
          forceLock: this.forceLock.bind(this),
          setAssessmentQuestionData: (values) => editingContext.addChange(newSetKeyValueChange(rootUid, "assessment_question", values)),
          setAllowLearningObjectivesEdit: this.props.setAllowLearningObjectivesEdit,
          ...currentMenuDetail,
          ...currentDocSelectionDetail,
        };
      } else {
        editingContext = weakMemoizedGenerateEditingContextAllFalse(
          userContext.id,
          version.changeAuthorIds,
        );
        editingContextExtra = {} as EditingContextExtra; // TODO: Remove as
      }

      /////////////////////
      // Mapping Context //
      /////////////////////
      const rootUid = mergedData.uid;
      let mappingContext;
      if (editingContext.shouldShowEditor) {
        const isAssessmentQuestion = documentContext.kind === "assessment_question_mcq";
        if (isAssessmentQuestion) {
          mappingContext = {
            ...mappingData,
            proposedLearningObjectiveReusableObjectId: mergedData.proposedLearningObjectiveReusableObjectId,
            setLearningObjectiveReusableObjectId: (loRoId: number) => editingContext.addChange(newSetKeyValueChange(rootUid, "proposedLearningObjectiveReusableObjectId", loRoId)),
            proposedSystem: mergedData.system,
            setSystem: (loros: string) => editingContext.addChange(newSetKeyValueChange(rootUid, "system", loros)),
            proposedClinicalFocus: mergedData.clinical_focus || "",
            setClinicalFocus: (loros: string) => editingContext.addChange(newSetKeyValueChange(rootUid, "clinical_focus", loros)),
          }
        } else {
          mappingContext = {
            ...mappingData,
            proposedLearningObjectiveReusableObjects: mergedData.proposedLearningObjectiveReusableObjects,
            setProposedLearningObjectiveReusableObjects: (loros) => editingContext.addChange(newSetKeyValueChange(rootUid, "proposedLearningObjectiveReusableObjects", loros)),
          }
        }
      }

      /////////////////////
      // PROJECT CONTEXT //
      /////////////////////
      const projectContext = {
        currentAssignmentId: this.props.currentAssignmentId,
        nextVersionId: this.props.nextVersionId,
        activeMemberId: this.props.activeMemberId,
        activeMemberName: this.props.activeMemberName,
        projectProductionAssociate: this.props.projectProductionAssociate,
        activeUserId: this.props.activeUserId,
        checklistContents: this.props.checklistContents,
      }

      ////////////////////////////
      // ReasoningTool Context //
      //////////////////////////
      const reasoningToolContext = {
        ...this.props.reasoningToolUi,
        togglePanel: this.props.togglePanel,
        togglePanelDeleting: this.props.togglePanelDeleting,
        panelNodeEditStart: this.props.panelNodeEditStart,
        panelNodeEditEnd: this.props.panelNodeEditEnd,
      };

      //////////////////////
      // HotKey Handlers //
      ////////////////////
      let keyHandlers;
      if (editingContext.shouldShowEditor) {
        keyHandlers = {
          undo: (event?: KeyboardEvent) => {this.props.undo(); return false;},
          redo: (event?: KeyboardEvent) => {this.props.redo(); return false;},
          save: (event?: KeyboardEvent) => {this.props.save(); return false;},
          modReturn: (event?: KeyboardEvent) => {
            if (window.document.activeElement && window.document.activeElement instanceof HTMLElement) {window.document.activeElement.blur();}
            this.props.clearCurrentDocSelection();
            return false;
          },
          tab: (event?: KeyboardEvent) => !this.props.tabAtSelection(),
          shiftTab: (event?: KeyboardEvent) => !this.props.shiftTabAtSelection(),
          bold: (event?: KeyboardEvent) => !this.props.toggleStyleAtSelection("bold"),
          italic: (event?: KeyboardEvent) => !this.props.toggleStyleAtSelection("italic"),
          underline: (event?: KeyboardEvent) => !this.props.toggleStyleAtSelection("underline"),
          superscript: (event?: KeyboardEvent) => !this.props.toggleStyleAtSelection("superscript"),
          subscript: (event?: KeyboardEvent) => !this.props.toggleStyleAtSelection("subscript"),
          find: (event?: KeyboardEvent) => () => {editingContext.setCurrentMenu(FIND_AND_REPLACE); return false;},
        }
      }

      //////////////////////
      // Integration Info //
      //////////////////////
      const integrationInfo = weakMemoizedGenerateIntegrationInfo(
        mergedData.final_diagnoses,
        mergedData.clinical_excellence,
        mergedData.basic_science_disciplines,
      )

      const HotKeysAnyType = HotKeys;
      return (
        <HotKeysAnyType keyMap={keyMap} handlers={keyHandlers}>
          <NewEditDoc
            key="doc"
            documentContext={documentContext}
            currentVersion={currentVersion}
            userContext={userContext}
            displayContext={displayContext}
            editingContext={editingContext}
            editingContextExtra={editingContextExtra}
            mappingContext={mappingContext}
            mediaContext={mediaContext}
            reasoningToolContext={reasoningToolContext}
            integrationInfo={integrationInfo}
            projectContext={projectContext}
            mergedData={mergedData}
            changeData={version.changeData}
            displayUid={displayUid}
            documentSetId={documentSetId}
            changeSummaries={versionExtraData.changeSummaries}
            userState={documentUserState?.state}
            featureFlag={featureFlag}
            isEditor={this.props.isEditor}
            allLearningObjectiveOptions={allLearningObjectiveOptions}
            learningObjectiveActionsGenerator={this.props.learningObjectiveActionsGenerator}
          />
          {editingContext.isEditing && editingContextExtra.isActiveEditData && <Beforeunload onBeforeunload={() => 'You’ll lose your data!'} />}
        </HotKeysAnyType>
      );
    } else {
      return <GenLoadingSpinner />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewEditDocContainer);
