import React from 'react';
import ReactDOM from 'react-dom';
import GenSortTable from 'Gen/SortTable/component'
import TeachingPointModal from 'NewEditDoc/TeachingPointModal/component'

import TableDownArrowImage from "images/table-down_arrow.png";
import TableUpArrowImage from "images/table-up_arrow.png";

class CohortProgressesDeliveriesTable extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      cohorts: this.props.cohorts,
      search: '',
      cohortFiltered: this.props.rows,
      filteredData: this.props.rows,
      sortCol: 'status',
      sortDir: 'asc',
      assessment_date: '',
      discipline: '',
      system: '',
      clinical_focus: '',
      purpose: '',
    };
  }

  get cohortFilters() {
    return ['assessment_date','discipline','purpose']
  }

  get clcFractionNames() {
    return [
      'excel_fraction',
      'competent_fraction',
      'unsure_fraction',
      'novice_fraction',
      'warning_fraction'
    ]
  }

  sortDeliveries = (sortCol, sortDir) =>{
    var data = this.state.filteredData
    if(sortCol == "clc") {
      return this.sortByClc(sortDir)
    }
    data.sort((a, b) => {
      let fa = a[sortCol].toLowerCase(),
          fb = b[sortCol].toLowerCase();
      if (fa < fb) {
          return sortDir == 'asc' ? -1 : 1;
      }
      if (fa > fb) {
        return sortDir == 'asc' ? 1 : -1;
      }
      return 0;
    });
    return data
  }

  sortImage = (sortCol)=>{
    return this.state.sortCol == sortCol ? this.state.sortDir == 'asc' ? TableDownArrowImage : TableUpArrowImage : TableDownArrowImage
  }

  sortByClc(sortDir) {
    let data = this.state.filteredData
    data.sort((a, b) => {
      let dir = 0
      for (const name of this.clcFractionNames) {
        let fa = a[name],
            fb = b[name]
        if (fa < fb) {
          dir = sortDir == 'asc' ? -1 : 1
        }
        if (fa > fb) {
          dir = sortDir == 'asc' ? 1 : -1
        }
        if (dir == 0) continue
        break
      }
      return dir
    })
    return data
  }

  async getFilteredDetails(ids) {
    let params = "?"
    for (const id of ids) {
      params += `&ids[]=${id}`
    }
    const response = await fetch(this.props.filter_url + params)
    return response.json()
  }

  filtersRow() {
    if (this.props.multiple_cohorts) {
      return (
        <div>
          <div className="filtersRow reportSelectFilters">
            <div className="form-inline">
              <select className= 'form-control filterSelect' value={this.state.assessment_date} onChange={(val) => {
                  this.setFilters('assessment_date', val.target.value)
                }}>
                <option value="">Assessment Date</option>
                {this.props.assessment_date_options.map(function(option){
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>

              <select className= 'form-control filterSelect' value={this.state.discipline} onChange={(val) => {
                  this.setFilters('discipline', val.target.value)
                }}>
                <option value="">Discipline</option>
                {this.props.discipline_options.map(function(option){
                  return (
                    <option key={option} value={option}>
                      {option.replace('Aquifer ', '')}
                    </option>
                  );
                })}
              </select>

              <select className= 'form-control filterSelect' value={this.state.purpose} onChange={(val) => this.setFilters('purpose', val.target.value)}>
                <option value="">Purpose</option>
                {this.props.purpose_options.map(function(option){
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>

              <select className= 'form-control filterSelect' value={this.state.system || 'System'} onChange={(val) => this.setState(this.setFilters('system', val.target.value))}>
                <option value="">System</option>
                {this.props.system_options.map(function(option){
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>

              <select className= 'form-control filterSelect' value={this.state.clinical_focus || "Clinical Focus"} onChange={(val) => this.setState(this.setFilters('clinical_focus', val.target.value))}>
                <option value="">Clinical Focus</option>
                {this.props.clinical_focus_options.map(function(option){
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="filtersRow" style={{justifyContent: 'center', marginBottom: '-10px'}}>
            {this.state.assessment_date.length > 0 && <p onClick={() =>{this.clearFilters('assessment_date')}} className='filterSelectedVal'>{this.state.assessment_date}  X</p>}
            {this.state.discipline.length > 0 && <p onClick={() =>{this.clearFilters('discipline')}} className='filterSelectedVal'>{this.state.discipline.replace('Aquifer ', '')}  X</p>}
            {this.state.system.length > 0 && <p onClick={() =>{this.setState(this.clearFilters('system'))}} className='filterSelectedVal'>{this.state.system}  X</p>}
            {this.state.purpose.length > 0 ? <p onClick={() =>{this.clearFilters('purpose')}} className='filterSelectedVal'>{this.state.purpose}  X</p> : null}
            {this.state.clinical_focus.length > 0 && <p onClick={() =>{this.setState(this.clearFilters('clinical_focus'))}} className='filterSelectedVal'>{this.state.clinical_focus}  X</p>}
          </div>
        </div>
      )
    }
  }

  getCorrectResponses(row) {
    if (row.accuracy_fraction.length === 0) {
      return "N/A"
    } else if (this.props.multiple_cohorts) {
      return row.accuracy_fraction
    } else {
      return `${row.accuracy_percentage}%`
    }
  }

  getClcStyle(percentage) {
    if (percentage <= 0) {
      return { display: 'none' }
    }
    return { width: `${percentage}%` }
  }

  clearFilters(currentFilterCol){
    var filters = {}
    filters[currentFilterCol] = ''
    if(this.cohortFilters.includes(currentFilterCol)) {
      let ids = []
      switch (currentFilterCol) {
        case 'assessment_date':
          ids = this.props.cohorts.filter((cohort) => {
            return cohort.course_name.indexOf(this.state.discipline) > -1 &&
                   cohort.purpose.indexOf(this.state.purpose) > -1
          }).map(c=>c.delivery_id)
          break;
        case 'discipline':
          ids = this.props.cohorts.filter((cohort) => {
            return cohort.date_range.indexOf(this.state.assessment_date) > -1 &&
                   cohort.purpose.indexOf(this.state.purpose) > -1
          }).map(c=>c.delivery_id)
          break;
        case 'purpose':
          ids = this.props.cohorts.filter((cohort) => {
            return cohort.date_range.indexOf(this.state.assessment_date) > -1 &&
                   cohort.course_name.indexOf(this.state.discipline) > -1
          }).map(c=>c.delivery_id)
          break;
      }
      this.getFilteredDetails(ids).then((data) => {
        filters.cohortFiltered = data.details
        filters.filteredData = filters.cohortFiltered.filter((item) => item.system.indexOf(this.state.system) > -1 &&
          ((item.learning_objective || '').toLowerCase().indexOf(this.state.search) > -1 ||
          (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search) > -1 ||
          (item.case || '').toLowerCase().indexOf(this.state.search) > -1) &&
          item.clinical_focus.indexOf(this.state.clinical_focus) > -1
        )
        this.setState(filters)
      })
    } else if(currentFilterCol == 'clinical_focus') {
      filters.filteredData = this.state.cohortFiltered.filter((item) => ((item.learning_objective || '').toLowerCase().indexOf(this.state.search) > -1 ||
        (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search) > -1 ||
        (item.case || '').toLowerCase().indexOf(this.state.search) > -1) &&
        item.system.indexOf(this.state.system) > -1 &&
        item.clinical_focus.indexOf('') > -1
      )
    } else if(currentFilterCol == 'system') {
      filters.filteredData = this.state.cohortFiltered.filter((item) => ((item.learning_objective || '').toLowerCase().indexOf(this.state.search) > -1 ||
        (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search) > -1 ||
        (item.case || '').toLowerCase().indexOf(this.state.search) > -1) &&
        item.system.indexOf('') > -1 &&
        item.clinical_focus.indexOf(this.state.clinical_focus) > -1
      )
    }

    return filters
  }

  setFilters(currentFilterCol, value) {
    var filters = {}
    filters[currentFilterCol] = value
    if(this.cohortFilters.includes(currentFilterCol)) {
      let ids = []
      switch (currentFilterCol) {
        case 'assessment_date':
          ids = this.props.cohorts.filter((cohort) => {
            return cohort.date_range.indexOf(value) > -1 &&
                   cohort.course_name.indexOf(this.state.discipline) > -1 &&
                   cohort.purpose.indexOf(this.state.purpose) > -1
          }).map(c=>c.delivery_id)
          break;
        case 'discipline':
          ids = this.props.cohorts.filter((cohort) => {
            return cohort.date_range.indexOf(this.state.assessment_date) > -1 &&
                   cohort.course_name.indexOf(value) > -1 &&
                   cohort.purpose.indexOf(this.state.purpose) > -1
          }).map(c=>c.delivery_id)
          break;
        case 'purpose':
          ids = this.props.cohorts.filter((cohort) => {
            return cohort.date_range.indexOf(this.state.assessment_date) > -1 &&
                   cohort.course_name.indexOf(this.state.discipline) > -1 &&
                   cohort.purpose.indexOf(value) > -1
          }).map(c=>c.delivery_id)
          break;
      }
      this.getFilteredDetails(ids).then((data) => {
        filters.cohortFiltered = data.details
        filters.filteredData = filters.cohortFiltered.filter((item) => item.system.indexOf(this.state.system) > -1 &&
          ((item.learning_objective || '').toLowerCase().indexOf(this.state.search) > -1 ||
          (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search) > -1 ||
          (item.case || '').toLowerCase().indexOf(this.state.search) > -1) &&
          item.clinical_focus.indexOf(this.state.clinical_focus) > -1
        )
        this.setState(filters)
      })
    } else if(currentFilterCol == 'clinical_focus') {
      filters.filteredData = this.state.cohortFiltered.filter((item) => item.clinical_focus.indexOf(value) > -1 &&
        item.system.indexOf(this.state.system) > -1 &&
        ((item.learning_objective || '').toLowerCase().indexOf(this.state.search) > -1 ||
        (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search) > -1 ||
        (item.case || '').toLowerCase().indexOf(this.state.search) > -1)
      )
    } else if(currentFilterCol == 'system') {
      filters.filteredData = this.state.cohortFiltered.filter((item) => item.system.indexOf(value) > -1 &&
        item.clinical_focus.indexOf(this.state.clinical_focus) > -1 &&
        ((item.learning_objective || '').toLowerCase().indexOf(this.state.search) > -1 ||
        (item.teaching_point_url_name || '').toLowerCase().indexOf(this.state.search) > -1 ||
        (item.case || '').toLowerCase().indexOf(this.state.search) > -1)
      )
    }

    return filters
  }

  getTeachingPointModal(row) {
    if (row.teaching_point_url_name != 'N/A') {
      return <TeachingPointModal
        title={row.teaching_point_url_name}
        docType="ReusableObject"
        documentId={row.teaching_point?.id}
        versionId={row.teaching_point_publish_variant_id}
        currentUser={this.props.user}
        isAdmin={this.props.admin}
        currentRoleName="Student"
        permissions={{}}
        displayUid={row.teaching_point?.uid}
      />
    }
  }

  render() {

    return <>
      <div className="detailsSection">
        <div className="col-md-12">
          <div className="sectionTitleRow">
            <div className="educator-detail-heading">
              <h2 className="reportTopSectionTitle">{this.props.multiple_cohorts ? 'Item Detail' : `Item Detail - ${this.props.students_count} students`} </h2>
              <div className="info-bubble">
                <span className="tooltip-box small after above-center tab-2">
                  % correct responses and CLC breakdown are based on submitted assessments only. The item details shown in the table below reflect the students who submitted responses for that item only.
                  {this.props.multiple_cohorts ? " As such, the denominators may be different from the number of students in a given cohort." : ""}
                </span>
              </div>
            </div>
            <div className="form-group" style={{width: 400}}>
              <label className="bottomSectionGraphLegendText">Search</label>
              <input type="text" name="search" placeholder='Search' className="form-control" onChange={(e) =>{
                this.setState({
                  filteredData: this.props.rows.filter((item) => ((item.learning_objective || '').toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                  (item.teaching_point_url_name || '').toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
                  (item.case || '').toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) &&
                  item.clinical_focus.indexOf(this.state.clinical_focus) > -1 &&
                  item.system.indexOf(this.state.system) > -1),
                  search: e.target.value.toLowerCase()
                });
              }} />
            </div>
          </div>

          {this.filtersRow()}

          <table className="detailsTable">
            <thead>
              <tr>
                <th className="bottomSectionGraphLegendText">Learning Objective</th>
                <th className="bottomSectionGraphLegendText">Teaching Point</th>
                <th
                  className='bottomSectionGraphLegendText tableSortCol'
                  onClick={() => {
                    this.setState({
                    sortCol: 'clinical_focus',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortDeliveries('clinical_focus', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })
                  }}
                >
                  <div>Clinical Focus <img src={this.sortImage('clinical_focus')} alt="case name" className="icon-case_name" /></div>
                </th>
                <th
                  className='bottomSectionGraphLegendText tableSortCol'
                  onClick={() => this.setState({
                    sortCol: 'system',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortDeliveries('system', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })
                  }
                >
                  <div>System <img src={this.sortImage('system')} alt="case name" className="icon-case_name" /></div>
                </th>
                <th className='bottomSectionGraphLegendText'>{this.props.multiple_cohorts ? "Correct Responses" : "% Correct Responses"}</th>
                <th
                  className='bottomSectionGraphLegendText tableSortCol clc'
                  onClick={() => this.setState({
                    sortCol: 'clc',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortDeliveries('clc', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })
                  }
                >
                  <div>Clc Breakdown <img src={this.sortImage('clc')} alt="case name" className="icon-case_name" /></div>
                </th>
                <th className="bottomSectionGraphLegendText">Aquifer Case</th>
                <th className="bottomSectionGraphLegendText">% of Students Completing Case</th>
              </tr>
            </thead>
            <tbody>
              {this.state.filteredData.map((row, i) => {
                return <tr key={`cohortProgressTable${i}`}>
                  <td>{row.learning_objective}</td>
                  <td>
                    {this.getTeachingPointModal(row)}
                  </td>
                  <td><p className="diagnosticCell">{row.clinical_focus}</p></td>
                  <td><p className="systemCell">{row.system}</p></td>
                  <td className="centerColumn">{this.getCorrectResponses(row)}</td>
                  <td>
                    <div className="row graphSectionRow">
                      <div className="overallPerformancesBar excel" style={this.getClcStyle(row.excel_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.excel_fraction)}%</p></div>
                      <div className="overallPerformancesBar competent" style={this.getClcStyle(row.competent_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.competent_fraction)}%</p></div>
                      <div className="overallPerformancesBar unsure" style={this.getClcStyle(row.unsure_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.unsure_fraction)}%</p></div>
                      <div className="overallPerformancesBar novice" style={this.getClcStyle(row.novice_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.novice_fraction)}%</p></div>
                      <div className="overallPerformancesBar warning" style={this.getClcStyle(row.warning_fraction)}><p className="bottomGraphIndicatorBarPercentText">{Math.round(row.warning_fraction)}%</p></div>
                    </div>
                  </td>
                  <td style={{color: "#110EBF"}}>{row.document_set_document_relation_path != 'N/A' ? <a style={{color: '#1b5599', textDecoration: 'underline'}} target="_blank" href={row.document_set_document_relation_path}>{row.case}</a> : 'N/A'}</td>
                  <td className="centerColumn">{Math.round(row.case_completed_fraction)}%</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  }
}

export default CohortProgressesDeliveriesTable;
