import {
  compact,
  difference,
  last,
  partition,
  uniq,
  zip,
} from "lodash";

import generateUUID from "../../generateUUID";

import { convertAllInlineNodesToRangeJSON } from "../Convert/RangeInlineJSON";

import * as DocConvertPlainText from "../Convert/PlainText";

export function reflow(json: DocSectionType<InlineHtmlNode>, documentId: number) {
  const root_section = json;

  let end_of_case_text = root_section.content.find((node) => node.uid === "end_of_case_text");
  if (!end_of_case_text) {
    end_of_case_text = endOfCaseText(root_section.title);
  }

  let cards = root_section.content.filter((node) => node.type === "section");
  cards.forEach((section) => {
    section.classes = uniq((section.classes || []).concat(["card"]));
  });

  let feedback_cards, remaining_cards, finish_case_cards, finish_case_card;
  let release_notes_cards, learning_objectives_cards, case_synopsis_cards;
  let references_cards, saq_cards, regular_cards;

  remaining_cards = cards;
  [feedback_cards, remaining_cards] = partitionByClass(remaining_cards, "feedback_card");
  [finish_case_cards, remaining_cards] = partitionByClass(remaining_cards, "finish_case_card");
  finish_case_card = finish_case_cards[0];
  [release_notes_cards, remaining_cards] = partitionByClass(remaining_cards, "release_notes_card");
  [learning_objectives_cards, remaining_cards] = partition(remaining_cards, (node) => (/Learning Objectives/i).test(node.title.text));
  [case_synopsis_cards, remaining_cards] = partitionByClass(remaining_cards, "case_synopsis_card");
  [references_cards, remaining_cards] = partition(remaining_cards, (node) => node.classes && node.classes.includes("references_card") && (/References/).test(node.title.text));
  [saq_cards, remaining_cards] = partitionByClass(remaining_cards, "saq_card");
  regular_cards = remaining_cards;

  finish_case_card = finish_case_card || finishCaseCard();
  if (feedback_cards.length === 0) { feedback_cards = feedbackCards(); }
  if (release_notes_cards.length === 0) { release_notes_cards = releaseNotesCards(); }
  if (learning_objectives_cards.length === 0) { learning_objectives_cards = learningObjectivesCards(); }
  if (case_synopsis_cards.length === 0) { case_synopsis_cards = caseSynopsisCards(); }

  const hidden_cards = case_synopsis_cards.concat(references_cards);
  hidden_cards.forEach((section) => {
    section.invisible = true;
  });

  let finish_case_button = null;
  let finish_case_text = null;
  let case_summary_button = null;
  let finish_case_saq_text = null;

  cards.forEach((section) => {
    section.content = section.content.filter((node) => {
      if (node.classes && node.classes.includes("finish_case_button")) {
        finish_case_button = node;
        return false;
      } else if ( node.type === "paragraph" && (
          (/Well done! You have completed/i).test(node.content.text)
          || (node.classes && node.classes.includes("finish_case_text"))
      )) {
        finish_case_text = node;
        return false;
      } else if (node.classes && node.classes.includes("case_summary_button")) {
        case_summary_button = node;
        return false;
      } else if ( node.type === "paragraph" && (
        node.content.text === "You may now continue to the optional self-assessment questions for you to assess what you have learned in this case."
        || node.content.text === "You may now continue to the  self-assessment questions for you to assess what you have learned in this case."
        || (node.classes && node.classes.includes("finish_case_saq_text"))
      )) {
        finish_case_saq_text = node;
        return false;
      } else if (node.uid === "feedback_introduction_text") {
        return false;
      } else {
        return true;
      }
    });
  });

  const not_continue_cards = difference(cards, regular_cards);
  not_continue_cards.forEach((section: any) => { // TODO: Remove any
    section.content = section.content.filter((node) => !(node.classes && node.classes.includes("continue_button")));
  });

  const not_feedback_cards = difference(cards, feedback_cards);
  not_feedback_cards.forEach((section: any) => { // TODO: Remove any
    section.content = section.content.filter((node) => !(node.classes && node.classes.includes("feedback_button")));
  });

  const regular_cards_uids = regular_cards.map((card) => card.uid);
  const zipped_regular_cards = zip(regular_cards, regular_cards_uids.slice(1).concat([feedback_cards[0].uid]));

  zipped_regular_cards.forEach(([card, next_uid]: [any, any]) => { // TODO: Remove any
    const question_sections = card.content.filter((node) => node.type === "section" && node.classes && node.classes.includes("question"));
    const submit_buttons = compact(question_sections.map((question_section) => question_section.content.find((node) => node.type === "button" && node.title.text === "Submit")));
    const old_submit_buttons_actions = submit_buttons.flatMap((submit_button: any) => submit_button.onClick); // TODO: Remove any

    question_sections.forEach((question_section) => {
      addSubmitButtonActionsToQuestionSection(question_section);
    });

    let continue_button = card.content.filter((node) => node.classes && node.classes.includes("continue_button"))[0];
    if (!continue_button) {
      continue_button = continueButton(`continue_button_${card.uid}`);
      card.content = card.content.concat(continue_button);
    }

    continue_button.onClick = findActionOrNew(continue_button.onClick, [
      generateSetNodeKVPairAction(next_uid, "invisible", false),
      generateSetNodeKVPairAction(next_uid, "collapsed", false),
      generateSetNodeKVPairAction(card.uid, "collapsed", true),
      generateSetNodeKVPairAction(continue_button.uid, "invisible", true),
    ]);

    if (submit_buttons.length > 0) {
      const submit_button: any = submit_buttons[0]; // TODO: Remove any

      submit_button.onClick = submit_button.onClick.concat(findActionOrNew(old_submit_buttons_actions, generateSetNodeKVPairAction(continue_button.uid, "inactive", 1)));

      continue_button.inactive = 2;
    } else {
      delete continue_button.inactive;
    }
  });

  if (regular_cards.length > 0) {
    const feedback_introduction_text = feedbackIntroductionText();

    const last_regular_card: any = last(regular_cards);
    last_regular_card.content.splice(-1, 0, feedback_introduction_text);
  }

  const feedback_cards_uids = feedback_cards.map((card) => card.uid);
  const zipped_feedback_cards = zip(feedback_cards, feedback_cards_uids.slice(1).concat([finish_case_card.uid]));

  zipped_feedback_cards.forEach(([card, next_uid]: [any, any], index) => { // TODO: Remove any
    let feedback_button = card.content.find((node) => node.classes && node.classes.includes("feedback_button"));
    if (!feedback_button) {
      feedback_button = feedbackSubmitButton(`feedback_card_${index+1}_button`);
      card.content.push(feedback_button);
    }

    feedback_button.onClick = findActionOrNew(feedback_button.onClick, [
      generateSetNodeKVPairAction(next_uid, "invisible", false),
      generateSetNodeKVPairAction(next_uid, "collapsed", false),
      generateSetNodeKVPairAction(card.uid, "invisible", true),
      generateSetNodeKVPairAction(card.uid, "collapsed", true),
    ]);

    const rating_bar = card.content.find((node) => node.type === "ratingBar");

    if (rating_bar) {
      feedback_button.inactive = 2

      rating_bar.onClick = findActionOrNew(rating_bar.onClick, [
        generateSetNodeKVPairAction(feedback_button.uid, "inactive", 1),
      ]);
    }
  });

  if (!finish_case_button) {
    finish_case_button = finishCaseButton();
  }
  finish_case_card.content.push(finish_case_button);
  const old_finish_case_button_actions = finish_case_button["onClick"];

  finish_case_button.onClick = findActionOrNew(finish_case_button.onClick,
    generateSetNodeKVPairAction(finish_case_button.uid, "invisible", true),
  );

  if (!finish_case_text) {
    finish_case_text = finishCaseText();
  }
  finish_case_text.classes = uniq((finish_case_text.classes || []).concat(["finish_case_text"]));
  finish_case_card.content.push(finish_case_text);

  finish_case_button.onClick = finish_case_button.onClick.concat(findActionOrNew(old_finish_case_button_actions,
    generateSetNodeKVPairAction(finish_case_text.uid, "invisible", false),
  ));

  if (!case_summary_button) {
    case_summary_button = caseSummaryButton(documentId);
  }
  finish_case_card.content.push(case_summary_button);

  finish_case_button.onClick = finish_case_button.onClick.concat(findActionOrNew(old_finish_case_button_actions,
    generateSetNodeKVPairAction(case_summary_button.uid, "invisible", false),
  ));

  release_notes_cards.forEach((card) => {
    if (card["content"].length > 0) {
      finish_case_button.onClick = finish_case_button.onClick.concat(findActionOrNew(old_finish_case_button_actions,
        generateSetNodeKVPairAction(card.uid, "invisible", false),
      ));
    }
  });

  learning_objectives_cards.forEach((card) => {
    finish_case_button.onClick = finish_case_button.onClick.concat(findActionOrNew(old_finish_case_button_actions,
      generateSetNodeKVPairAction(card.uid, "invisible", false),
    ));
  });

  if (saq_cards.length > 0) {
    if (!finish_case_saq_text) {
      finish_case_saq_text = finishCaseSAQText();
    }
    finish_case_saq_text.classes = uniq((finish_case_saq_text.classes || []).concat(["finish_case_saq_text"]));
    finish_case_card["content"] << finish_case_saq_text;

    finish_case_button.onClick = finish_case_button.onClick.concat(findActionOrNew(old_finish_case_button_actions,
      generateSetNodeKVPairAction(finish_case_saq_text.uid, "invisible", false),
    ));

    saq_cards.forEach((saq_card) => {
      finish_case_button.onClick = finish_case_button.onClick.concat(findActionOrNew(old_finish_case_button_actions,
        generateSetNodeKVPairAction(saq_card.uid, "invisible", false),
      ));
    });
  }

  finish_case_button.onClick = finish_case_button.onClick.concat(findActionOrNew(old_finish_case_button_actions,
    generateSetNodeKVPairAction(end_of_case_text.uid, "invisible", false),
  ));

  saq_cards.forEach((saq_card) => {
    const question_sections = saq_card.content.filter((node) => node.type === "section" && node.classes && node.classes.includes("question"));
    question_sections.forEach((question_section) => {
      addSubmitButtonActionsToQuestionSection(question_section);
    });
  });

  cards = regular_cards.concat(
    feedback_cards,
    [finish_case_card],
    release_notes_cards,
    learning_objectives_cards,
    saq_cards,
    case_synopsis_cards,
    references_cards,
  );

  cards.forEach((card) => {
    card.invisible = true;
    card.collapsible = true;
    card.collapsed = true;
    card.nested = true;
  });

  regular_cards[0].collapsed = false;
  regular_cards[0].invisible = false;

  root_section.content = root_section.content.filter((node) => node.type !== "section");
  root_section.content = root_section.content.concat(cards);

  root_section.content = root_section.content.filter((node) => node.uid !== "end_of_case_text");
  root_section.content = root_section.content.concat([end_of_case_text]);

  return root_section;
}

function partitionByClass(nodes: DocBlockNode<InlineHtmlNode>[], partitionClass: string) {
  return partition(nodes, (node) => node.classes && node.classes.includes(partitionClass));
}

function endOfCaseText(title: string) {
  return convertAllInlineNodesToRangeJSON({
    type: "paragraph",
    uid: "end_of_case_text",
    content: `Thank you for completing ${DocConvertPlainText.fromAnyType(title)}.`,
    invisible: true,
  });
}

function finishCaseCard() {
  return convertAllInlineNodesToRangeJSON({
    type: "section",
    uid: "finish_case_card",
    classes: ["card", "finish_case_card"],
    title: "Case Summary Download",
    content: [],
    level: 1,
  });
}

function feedbackCard(number: number, feedbackParagraph: string) {
  return convertAllInlineNodesToRangeJSON({
    type: "section",
    uid: `feedback_card_${number}`,
    classes: ["card", "feedback_card"],
    title: `Feedback (${number} of 3)`,
    content: [
      {
        type: "paragraph",
        uid: `feedback_paragraph_${number}`,
        content: feedbackParagraph,
      },
      {
        type: "ratingBar",
        uid: `feedback_card_${number}_rating_bar`,
        maxValue: 5,
      },
      {
        type: "paragraph",
        uid: `feedback_required_${number}`,
        content: "*Required",
        classes: ["required_text"],
      },
      {
        type: "section",
        uid: `feedback_card_${number}_text_input_section`,
        collapsible: true,
        collapsed: true,
        nested: true,
        level: 2,
        title: "Comment (optional)",
        content: [
          {
            type: "textInput",
            uid: `feedback_card_${number}_text_input`,
            boxLines: 5,
            characterLimit: 1000,
          },
        ],
      },
      {
        type: "button",
        uid: `feedback_card_${number}_button`,
        classes: ["feedback_button"],
        title: "Submit",
      },
    ],
    level: 1,
  });
}

const FEEDBACK_PARAGRAPHS = [
  "This case was a valuable use of my time.",
  "I will apply what I learned to patient care.",
  "I would recommend this case to another student.",
];

function feedbackCards() {
  return FEEDBACK_PARAGRAPHS.map((feedbackParagraph, index) => feedbackCard(index + 1, feedbackParagraph));
}

function releaseNotesCards() {
  return [
    convertAllInlineNodesToRangeJSON({
      type: "section",
      uid: "release_notes_card",
      classes: ["card", "release_notes_card"],
      title: "Release Notes",
      content: [],
      level: 1,
    }),
  ];
}

function learningObjectivesCards() {
  return [
    convertAllInlineNodesToRangeJSON({
      type: "section",
      uid: "learning_objectives_card",
      classes: ["card", "learning_objectives_card"],
      title: "Learning Objectives",
      content: [],
      level: 1,
    }),
  ];
}

function caseSynopsisCards() {
  return [
    convertAllInlineNodesToRangeJSON({
      type: "section",
      uid: "case_synopsis_card",
      classes: ["card", "case_synopsis_card"],
      title: "Case Synopsis",
      content: [],
      level: 1,
    }),
  ];
}

function feedbackIntroductionText() {
  return convertAllInlineNodesToRangeJSON({
    uid: "feedback_introduction_text",
    type: "paragraph",
    classes: [
      "feedback_introduction_text",
    ],
    content: "This is the final page of the case. We value your perspective on the learning experience. After completing three required feedback ratings you can finish the case and access the case summary.",
  });
}

function feedbackSubmitButton(uid: string) {
  return convertAllInlineNodesToRangeJSON({
    type: "button",
    uid: uid,
    title: "Submit",
    classes: ["feedback_button"],
  });
}

function finishCaseButton() {
  return convertAllInlineNodesToRangeJSON({
    type: "button",
    uid: "finish_case_button",
    title: "Finish Case",
    classes: ["finish_case_button"],
  });
}

function finishCaseText() {
  return convertAllInlineNodesToRangeJSON({
    type: "paragraph",
    uid: "finish_case_text",
    classes: ["finish_case_text"],
    content: "Well done! You have completed the case. Click to download the case summary.",
    invisible: true,
  });
}

function caseSummaryButton(document_id: number) {
  return convertAllInlineNodesToRangeJSON({
    type: "button",
    uid: "case_summary_button",
    title: "Download Case Summary",
    classes: ["case_summary_button"],
    invisible: true,
    onClick: [
      {
        type: "action",
        action: "openExternalLink",
        arguments: {
          url: `https://medu2.meduapp.com/documents/${document_id}/case_summary.pdf`,
          key: "invisible",
          value: true,
        },
        uid: generateUUID(),
      },
    ],
  });
}

function finishCaseSAQText() {
  return convertAllInlineNodesToRangeJSON({
    type: "paragraph",
    uid: "finish_case_saq_text",
    classes: ["finish_case_saq_text"],
    content: "You may now continue to the optional self-assessment questions for you to assess what you have learned in this case.",
    invisible: true,
  });
}

function addSubmitButtonActionsToQuestionSection(question_section: DocSectionType<InlineHtmlNode>) {
  const submit_button = question_section.content.find((node) => node.type === "button" && node.title.text === "Submit");

  if (submit_button) {
    const old_submit_button_actions = submit_button.onClick;
    submit_button.onClick = [];

    const question = question_section.content.find((node) => ["categoryMatcher", "multipleChoice", "textInput", "cdqQuestion"].includes(node.type));
    if (question) {
      submit_button.onClick = submit_button.onClick.concat(findActionOrNew(old_submit_button_actions, [
        generateSetNodeKVPairAction(question.uid, "graded", true),
        generateSetNodeKVPairAction(question.uid, "inactive", 2),
      ]));
    }

    const answer_comment = question_section.content.find((node) => node.type === "section" && node.classes && node.classes.includes("answer_comment"));
    if (answer_comment) {
      submit_button.onClick = submit_button.onClick.concat(findActionOrNew(old_submit_button_actions, generateSetNodeKVPairAction(answer_comment.uid, "invisible", false)));
      answer_comment.invisible = true;
    }

    submit_button.onClick = submit_button.onClick.concat(findActionOrNew(old_submit_button_actions, generateSetNodeKVPairAction(submit_button.uid, "inactive", 2)));
  }
}

function findActionOrNew(action_array: Array<DocAction>, new_actions: DocAction | Array<DocAction>) {
  action_array = action_array || [];
  if (!Array.isArray(new_actions)) {
    new_actions = [new_actions];
  }

  return new_actions.map((new_action) => action_array.find((old_action) => (old_action.action === new_action.action) && (("arguments" in old_action && old_action.arguments) === ("arguments" in new_action && new_action.arguments))) || new_action);
}

function generateAction(action: ActionName, actionArguments) {
  return {
    type: "action",
    action: action,
    arguments: actionArguments,
    uid: generateUUID(),
  };
}

function generateSetNodeKVPairAction(uid: string, key: string, value) {
  return generateAction("setNodeKVPair", {
    uid: uid,
    key: key,
    value: value,
  }) as DocActionSetNodeKVPair;
}

function continueButton(uid: string) {
  return convertAllInlineNodesToRangeJSON({
    type: "button",
    uid: uid,
    title: "Continue",
    classes: ["continue_button"],
  });
}
