import * as React from "react";
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import * as R from "ramda";

interface DocContentMappingLOTPMapProps {
  activeLearningObjective?: LearningObjective;
  currentLearningObjectiveReusableObjects: Array<LearningObjectiveReusableObject>;
  proposedLearningObjectiveReusableObjects: Array<ProposedLearningObjectiveReusableObject>;
  reusableObjects: Array<ReusableObject>;
  setProposedLearningObjectiveReusableObjects: (loros: Array<ProposedLearningObjectiveReusableObject>) => void;
  userContext: UserContext;
};

interface DocContentMappingLOTPMapState {
  newLoro?: NewLoro;
  selectedReusableObjectId?: string | number;
};

export default class DocContentMappingLOTPMap extends React.Component<DocContentMappingLOTPMapProps, DocContentMappingLOTPMapState> {
  constructor(props: DocContentMappingLOTPMapProps) {
    super(props);
    this.state = {
      newLoro: undefined,
      selectedReusableObjectId: undefined,
    };
  }

  handleReusableObjectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    let selectedReusableObject
    if(event.target.value!=="") {
      selectedReusableObject = parseInt(event.target.value);
    }else{
      selectedReusableObject=""
    }
    const newLoro = { ...this.state.newLoro, reusable_object: selectedReusableObject }
    this.setState({selectedReusableObjectId: selectedReusableObject, newLoro: newLoro })
  };

  handleDeleteWithAlert(loro: LearningObjectiveReusableObject) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='react-confirm-alert-body'>
            <h1>You are deleting an LOTP dyad</h1>
            <p>Breaking this LOTP dyad could have additional curriculum mapping implications. Click the Continue button to proceed or Cancel to discard the deletion of this dyad.</p>
            <button className='aq-button'
              style={{marginRight: '5px'}}
              onClick={() => {
                this.handleDeleteLoro(loro);
                onClose();
              }}
            >
              Continue
            </button>
            <button className='aq-button' onClick={onClose}>Cancel</button>
          </div>
        );
      }
    });
  }

  propsedLearningObjectiveResuableObjectCompiled(): Array<ProposedLearningObjectiveReusableObject> {
    const {
      currentLearningObjectiveReusableObjects,
      proposedLearningObjectiveReusableObjects,
    } = this.props;

    const currLorosByLoIdRoId = R.mapObjIndexed(
      (loros) => R.fromPairs(R.map((loro) => [(loro as LearningObjectiveReusableObject).reusable_object_id, loro], loros)),
      R.groupBy((loro: any) => loro.learning_objective_id, currentLearningObjectiveReusableObjects) // TODO: REMOVE ANY
    ) as Record<number, Record<string, LearningObjectiveReusableObject>>

    return proposedLearningObjectiveReusableObjects.map((loro) =>
      R.path([loro.learning_objective_id, loro.reusable_object_id], currLorosByLoIdRoId) || loro
    )
  }

  handleDeleteLoro(loro: LearningObjectiveReusableObject) {
    let newProposedLoros = this.propsedLearningObjectiveResuableObjectCompiled().filter((target) => target.id !== loro.id)
    this.props.setProposedLearningObjectiveReusableObjects(newProposedLoros)
  }

  handleRestoreLoro(loro: LearningObjectiveReusableObject) {
    let newProposedLoros = this.propsedLearningObjectiveResuableObjectCompiled()
    newProposedLoros = newProposedLoros.concat([loro])
    this.props.setProposedLearningObjectiveReusableObjects(newProposedLoros)
  }

  handleSaveWithAlert() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='react-confirm-alert-body'>
            <p>When published, the selected content mappings for this Learning Objective will be available throughout the Aquifer curriculum. Click the Continue button to proceed or Cancel to discard the deletion of this dyad.</p>
            <button className='aq-button'
              style={{marginRight: '5px'}}
              onClick={() => {
                this.handleSaveNewLoro();
                onClose();
              }}
            >
              Continue
            </button>
            <button className='aq-button' onClick={onClose}>Cancel</button>
          </div>
        );
      }
    });
  }

  handleSaveNewLoro() {
    if (this.state.selectedReusableObjectId === undefined) {
      alert("Please select a reusable object.");
    } else {
      let reusableObjectToBeSaved = this.state.newLoro.reusable_object
      let roIds = this.props.proposedLearningObjectiveReusableObjects.map(ro => ro.id)
      let tempLoroIds = roIds.filter(ro => typeof(ro) === "string")
      let idNums = tempLoroIds.map(id => parseInt(id.substring(id.indexOf('_') + 1)))
      let newId
      if (idNums.length >= 1) {
        let maxId = Math.max(...idNums)
        newId = maxId + 1
      } else {
        newId = 1
      }
      let newLoroId = `newRo_${newId}`
      tempLoroIds.push(newLoroId)
      let loroToBeSaved = {
        id: newLoroId,
        learning_objective_id: this.props.activeLearningObjective.id,
        reusable_object_id: reusableObjectToBeSaved,
      }

      let newProposedLoros = this.propsedLearningObjectiveResuableObjectCompiled()
      newProposedLoros = newProposedLoros.concat([loroToBeSaved])
      this.props.setProposedLearningObjectiveReusableObjects(newProposedLoros)
      this.setState({ newLoro: undefined, selectedReusableObjectId: undefined })
    }
  }

  handleCancelNewLoro() {
    this.setState({newLoro: undefined})
  }

  render() {
    const {
      activeLearningObjective
    } = this.props;

    if (activeLearningObjective) {
      const propsedLearningObjectiveResuableObjectCompiled: Array<LearningObjectiveReusableObject | ProposedLearningObjectiveReusableObject> = this.propsedLearningObjectiveResuableObjectCompiled();
      const currentLearningObjectiveReusableObjects: Array<LearningObjectiveReusableObject | ProposedLearningObjectiveReusableObject> = this.props.currentLearningObjectiveReusableObjects

      let tableLorosDups = [...new Set(currentLearningObjectiveReusableObjects.concat(propsedLearningObjectiveResuableObjectCompiled))].filter((loro: any) => loro.learning_objective_id === activeLearningObjective.id) //TODO: Remove any
      let tableLoros = R.uniqBy((loro) => loro.id, tableLorosDups)

      if (this.state.newLoro) {
        tableLoros.push(this.state.newLoro)
      }
      const addTableRow = () => {
        const newLoro = {
          id: "placeholder",
          learning_objective: activeLearningObjective,
          reusable_object: null,
        }
        this.setState({newLoro: newLoro})
      }
      let addRowCell;
      if (this.props.userContext.permissions.allowedEditMode === "Editing" && this.state.newLoro === undefined && activeLearningObjective !== null) {
        addRowCell = (
          <div style={{textAlign: 'center', marginBottom: '5px'}}>
            <button className='aq-button' onClick={addTableRow}>Add Row</button>
          </div>
        )
      }
      let saveAndCancel;
      if (this.state.newLoro) {
        saveAndCancel = (
          <div style={{textAlign: 'center', marginBottom: '5px'}}>
            <button className='aq-button' style={{marginRight: '5px'}} onClick={() => this.handleSaveWithAlert()}>Save</button>
            <button className='aq-button' onClick={() => this.handleCancelNewLoro()}>Cancel</button>
          </div>
        )
      }
      let existingRoIds = this.props.currentLearningObjectiveReusableObjects.concat(this.propsedLearningObjectiveResuableObjectCompiled()).filter(loro => loro.learning_objective_id === activeLearningObjective.id).map(loro => loro.reusable_object_id)
      let reusableObjectOptions = this.props.reusableObjects.filter(ro => !(existingRoIds.includes(ro.id)))
      let tableRows = tableLoros.map((loro, i) => {
        const roDropdown =  <select onChange={this.handleReusableObjectChange.bind(this)} value={this.state.selectedReusableObjectId}>
                    <option value="">Select</option>
                    {
                      reusableObjectOptions.map((reusableObject) =>
                        <option key={reusableObject.id}
                                value={reusableObject.id}>{reusableObject.content_title}
                        </option>)
                    }
                  </select>
        let deleteCell
        let rowStyle = {}
        let proposedLoros = this.propsedLearningObjectiveResuableObjectCompiled()
        if(proposedLoros.find(x => x.id === loro.id) && (!loro.questions || loro.questions.length === 0)) {
          deleteCell = <button onClick={() => this.handleDeleteWithAlert(loro)}>Delete</button>
        } else if(this.props.currentLearningObjectiveReusableObjects.includes(loro) && !this.propsedLearningObjectiveResuableObjectCompiled().includes(loro)) {
          deleteCell = <button onClick={() => this.handleRestoreLoro(loro)}>Restore</button>
          rowStyle = {textDecorationLine: 'line-through', textDecorationStyle: 'solid'}
        }

        if (this.props.userContext.permissions.allowedEditMode !== "Editing") {
          deleteCell = ''
        }

        let loName = activeLearningObjective.name;

        let roName
        if (this.props.reusableObjects.find(x => x.id === loro.reusable_object_id)){
          roName = this.props.reusableObjects.find(x => x.id === loro.reusable_object_id).name;
        } else {
          roName = roDropdown
        }

        return (
          <tr style={rowStyle}>
            <td>{decodeURI(loName)}</td>
            <td>{roName}</td>
            <td>
              <div style={{textAlign: 'center'}}>
                {
                  loro.questions?.map((question) => (
                    <p><a href={`/versions/${question.editing_document.scheduled_review_version_id}/edit`}>{question.case_summary_identifier}</a></p>
                  ))
                }
              </div>
            </td>
            <td>
              <div style={{textAlign: 'center'}}>
                {deleteCell}
              </div>
            </td>
          </tr>
        );
      })

      return (
        <div>
          <h5 style={{backgroundColor: "#0095C9", padding: "5px"}}>Assessment - LOTP - System Map</h5>
          <div>
            <p style={{fontWeight: "bold"}}>
              The table below displays the Teaching Points that are mapped to this Learning Objective.
              Add another row if you wish to map this Learning Objective to additional Teaching Point(s).
              When you have finished mapping, click the Save button.
            </p>
            <table className="aq-mapping-table">
              <tbody>
                <tr>
                  <th className="aq-mapping-table-header">Learning Objective</th>
                  <th className="aq-mapping-table-header">Existing Map to Teaching Point</th>
                  <th className="aq-mapping-table-header">Assessment Questions Mapped to this Dyad</th>
                  <th className="aq-mapping-table-header"></th>
                </tr>
                {tableRows}
              </tbody>
            </table>
            {addRowCell}
            {saveAndCancel}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h5 style={{backgroundColor: "#0095C9", padding: "5px"}}>Please Select a Learning Objective</h5>
        </div>
      );
    }
  }
}
