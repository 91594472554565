import * as React from "react";

import ReasoningToolPanel from "./Panel";

import DocLearningObjectivesList from "../BlockNodes/LearningObjectivesList";

import AqButton from "../../Aq/Button";

import editGradientSmall from 'app/assets/images/edit_gradient_small.svg';

import {
  weakMemoizedGenerateEditingContextAllFalse,
} from "../../../helpers/Doc/context";

interface CaseLearningObjectivesPanelProps {
  learningObjectivesList: DocLearningObjectivesListType<InlineNode>;
  documentContext: DocumentContext;
  currentVersion: CurrentVersion;
  editingContext: EditingContext;
  userContext: UserContext;
  displayContext: DisplayContext;
  mediaContext: MediaContext;
  mappingContext: MappingContext;
  featureFlag: FeatureFlag;
};

export default function CaseLearningObjectivesPanel(props: CaseLearningObjectivesPanelProps) {
  const {
    learningObjectivesList,
    documentContext,
    currentVersion,
    editingContext,
    userContext,
    displayContext,
    mediaContext,
    mappingContext,
    featureFlag,
  } = props;

  let renderedLearningObjectives;
  if (learningObjectivesList) {
    renderedLearningObjectives = <DocLearningObjectivesList
      {...learningObjectivesList}
      documentContext={documentContext}
      currentVersion={currentVersion}
      editingContext={weakMemoizedGenerateEditingContextAllFalse()}
      userContext={userContext}
      displayContext={displayContext}
      mediaContext={mediaContext}
      featureFlag={featureFlag}
    />
  } else if (mappingContext?.learningObjectives && mappingContext.learningObjectives.length > 0) {
    renderedLearningObjectives = (
      <ul>
        { mappingContext.learningObjectives.map(learningObjective => <li>{learningObjective.name}</li>) }
      </ul>
    )
  } else {
    renderedLearningObjectives = <h3>No Learning Objectives</h3>;
  }

  const editLOsButton = (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}>
      <AqButton
        className="pink outline"
        onClick={() => {
          const learningObjectiveCard = document.querySelector('.learning_objectives_card')

          if (learningObjectiveCard) {
            const scrollPosition = learningObjectiveCard.getBoundingClientRect().top +  window.pageYOffset - 200;
            window.scrollTo({top: scrollPosition, behavior: 'smooth'});
          }
        }}
      >
        <div className="aq-button-text" style={{marginRight: '10px'}}>
          Edit Learning Objectives
        </div>

        <div>
          <img src={editGradientSmall} style={{height: '12px'}}></img>
        </div>
      </AqButton>
    </div>
  );

  return (
    <ReasoningToolPanel
      key="CASE LEARNING OBJECTIVES"
      title="CASE LEARNING OBJECTIVES"
    >
      <div className="reasoning-tool-panel-padding">
        {editLOsButton}
        {renderedLearningObjectives}
      </div>
    </ReasoningToolPanel>
  );
}
