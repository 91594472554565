import * as React from "react";

import * as classNames from "classnames";

import DocStyledText from "../BlockNodeHelpers/StyledText";

import { handleActions } from "../../../helpers/Doc/handleActions";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";

import analyticsModule from "../../../../application/analytics.js";

interface DocButtonProps extends DocButtonType<InlineNode>, BlockNodeAll {}

function DocButton(props: DocButtonProps) {
  const onClick = () => {
    if (!props.inactiveBool) {
      handleActions(props.onClick, props);
      if (props.title == 'Submit') {
        const caseName = props.documentContext.title
        analyticsModule.track('complete_saqs', { page: window.location.href, source: 'Case', case: caseName});
      }
    }
  };

  if(props.classes?.includes("case_summary_button")) {
    return <></>;
  }

  if(props.classes?.includes("finish_case_button")) {
    handleActions(props.onClick, props);
  }

  return (
    <div
      id={props.uid}
      data-is-node
      data-uid={props.uid}
      className={classNames("doc-button-slice", props.uid, {inactive: props.inactiveBool})}
      style={backgroundStyleFromNode(props)}
      contentEditable={false}
    >
      <button className="doc-button" onClick={onClick}>
        <DocStyledText
          uid={props.uid}
          content={props.title}
          contentKey={"title"}
          editingContext={props.editingContext}
          readOnly
          disableStyleTextEditing={props.disableStyleTextEditing}
        />
      </button>
    </div>
  );
}

export default React.memo(DocButton);
