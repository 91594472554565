import * as React from "react";

import * as classNames from "classnames";

import {
  EXPANDED_MOD_MAP_LOG,
  OPENED_MOD_MAP_LOG,
} from "../../../helpers/DocumentUserLog";

import DocBookmark from "../BlockNodeHelpers/Bookmark";
import DocNote from "../BlockNodeHelpers/Note";
import DocStyledText from "../BlockNodeHelpers/StyledText";

import GenButton from "../../Gen/Button/component";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";
import { MEDIA_PICKER } from "../../../helpers/Doc/menu";

import PlaceholderImage from "images/placeholder-image.png";

const onExpandCollapseClick = (toggleImageModal: () => void) => (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  e.stopPropagation();
  toggleImageModal();
};

interface DocImageProps extends DocImageType<InlineNode>, BlockNodeAll {
  inGallery?: boolean;
};

interface DocImageState {
  imageModalOn: boolean,
};

export default class DocImage extends React.PureComponent<DocImageProps, DocImageState> {
  constructor(props: DocImageProps) {
    super(props);

    this.state = {
      imageModalOn: false,
    };
  }

  openPicker(e: React.MouseEvent<HTMLAnchorElement>) {
    const {
      editingContext,
    } = this.props;

    if (editingContext.isEditing) {
      editingContext.setCurrentMenu(MEDIA_PICKER, { mediaType: "image", uid: this.props.uid });
    }

    e.stopPropagation();
  }

  toggleImageModal() {
    if (!this.state.imageModalOn && this.props.classes?.includes("mod-map-image")) {
      this.props.userContext.logEvent(EXPANDED_MOD_MAP_LOG);
    }
    this.setState({imageModalOn: !this.state.imageModalOn});
  };

  onImageExternalLinkClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation();
    if (this.props.classes?.includes("mod-map-image")) {
      this.props.userContext.logEvent(OPENED_MOD_MAP_LOG);
    }
    return true;
  }

  render() {
    let imageURL, imageExternalLink;
    if (this.props.url) {
      imageURL = this.props.url;
      const asset: Partial<DigitalAsset> = this.props.mediaContext.images.find((i) => { return i.distribution_url === this.props.url || this.props.url.includes(i.asset_path); }) || {};
      const external_id = asset.external_identifier || this.props.url.split('/').pop()?.split('_')[0];
      imageExternalLink = `/documents/${this.props.documentContext.id}/asset_pdf.pdf?external_id=${external_id}`;
    } else if (this.props.uri && this.props.uri !== "blank.jpg") {
      Rollbar.warning("Deprecated render DocImage with uri called");
      imageURL = this.props.currentVersion.localImageURL(this.props.uri);
      imageExternalLink = `/documents/${this.props.documentContext.id}/image_pdf.pdf?image_name=${encodeURIComponent(this.props.uri)}`;
    } else {
      imageURL = PlaceholderImage;
      imageExternalLink = "#";
    }

    const image = (
      <figure
        id={this.props.uid}
        className={classNames("doc-image", this.props.uid)}
        contentEditable={false}
      >
        <div className="doc-image-wrapper" onClick={this.toggleImageModal.bind(this)}>
          <img style={backgroundStyleFromNode(this.props)} src={imageURL} />
          <a href="#" title="Larger Version" onClick={onExpandCollapseClick(this.toggleImageModal.bind(this))} className="doc-image-expand-button">
            <i className="fa fa-expand"></i>
          </a>
          <a href={imageExternalLink} onClick={this.onImageExternalLinkClick.bind(this)} target="_blank" title="Open in New Tab" className="doc-image-external-link-button">
            <i className="fa fa-external-link-alt"> </i>
          </a>
          {this.props.editingContext.isEditing && this.props.userContext.permissions.viewMediaAssets && <GenButton
              key="picker-button"
              className="doc-image-picker-button"
              name={<i className="fa fa-image"></i>}
              highlighted
              small
              onClick={this.openPicker.bind(this)}
            />}
        </div>
        <figcaption>
          <DocStyledText
            uid={this.props.uid}
            content={this.props.title || ""}
            contentKey={"title"}
            editingContext={this.props.editingContext}
            placeholder={"Enter Image caption here"}
            independentlyEditable
            disableStyleTextEditing={this.props.disableStyleTextEditing}
          />
        </figcaption>
        { this.state.imageModalOn && (
          <div className="image-modal-outer" onClick={this.toggleImageModal.bind(this)}>
            <div className="image-modal-inner">
              <img src={imageURL} />
              <a title="Back" href="#" onClick={onExpandCollapseClick(this.toggleImageModal.bind(this))} className="doc-image-compress-button">
                <i className="fa fa-compress"> </i>
              </a>
            </div>
          </div>
        )}
      </figure>
    );

    if (this.props.inGallery) {
      return (
        image
      );
    } else {
      return (
        <>
          <span></span>
          <div
            data-is-node
            data-uid={this.props.uid}
            className={classNames("doc-image-single", this.props.uid, {[`doc-image-alignment-${this.props.alignment}`]: this.props.alignment, "focused-bookmark": this.props.isFocusedBookmark && !this.props.simple})}
            contentEditable={false}
          >
            {image}
            { !this.props.simple && <DocBookmark {...this.props} /> }
            { !this.props.simple && <DocNote {...this.props} /> }
          </div>
          <span></span>
        </>
      );
    }
  }
}
