import memoize from "memoize-weak";

import { uniq } from "lodash";

import { displayModes } from "./displayMode";
import { generateColorLookup } from "./colors";

import { changesDisplayModes } from "./changes";

import {
  generateNotesStateFunctions,
  generateFindingsStateFunctions,
  generateDiagnosesStateFunctions,
} from "./generateReasoningToolStateFunctions";

export const weakMemoizedGenerateCurrentDocument = memoize(generateCurrentDocument);
export function generateCurrentDocument(
  document: Document,
  docType: DocType | undefined, // TODO: Narrow down
  reload: () => void,
) {
  return {
    ...document,
    docType,
    reload,
  };
}

export const weakMemoizedGenerateCurrentVersion = memoize(generateCurrentVersion);
export function generateCurrentVersion(
  id: number,
  published: boolean,
  awsFolderUrl: string,
  coverImage: string,
  onlyOneUncollapsedSection: boolean,
  disableBookmarks: boolean,
  reload: () => void,
) {
  return {
    id,
    published,
    awsFolderUrl,
    localImageURL: (name: string) => `${awsFolderUrl}/compressed/images/${name}`,
    localAudioURL: (name: string) => `${awsFolderUrl}/original/audios/${name}`,
    localVideoURL: (name: string) => `${awsFolderUrl}/original/videos/${name}`,
    localCaptionURL: (name: string) => `${awsFolderUrl}/original/captions/${name}`,
    coverImage,
    onlyOneUncollapsedSection,
    disableBookmarks,
    reload,
  };
}

export const weakMemoizedGenerateCurrentUser = memoize(generateCurrentUser);
export function generateCurrentUser(
  currentUser: User,
  currentGroupRoleDefinition: GroupRoleDefinition | undefined,
  isAdmin: boolean,
  sessionId: number | undefined, // TODO: Remove undefined
  permissions: AqPermissions,
  loadDocumentUserState: () => void,
  addStateDocumentUserState: (state: UserStateStripped, callback?: () => void) => void,
  destroyDocumentUserState: () => void,
  submitExam: ((successNotice: string) => void) | undefined, // TODO: Remove undefined
  pauseExam: (() => void) | undefined, // TODO: Remove undefined
  logEvent: (logType: string, args?: Record<string, any>) => void,
  rootUID: string,
) {
  return {
    id: currentUser.id,
    firstName: currentUser.first_name,
    lastName: currentUser.last_name,
    email: currentUser.email,
    groupRoleDefinition: currentGroupRoleDefinition,
    isAdmin: isAdmin,
    sessionId: sessionId,
    permissions: permissions,
    addState: addStateDocumentUserState,
    resetState: () => {
      destroyDocumentUserState(),
      loadDocumentUserState();
    },
    submitExam: submitExam,
    pauseExam: pauseExam,
    logEvent: logEvent,
    ...generateNotesStateFunctions(addStateDocumentUserState, rootUID),
    ...generateFindingsStateFunctions(addStateDocumentUserState, rootUID),
    ...generateDiagnosesStateFunctions(addStateDocumentUserState, rootUID),
  };
}

export const weakMemoizedGenerateDisplayContext = memoize(generateDisplayContext);
export function generateDisplayContext(
  displayMode: DisplayMode,
  setDisplayMode: (displayMode: DisplayMode) => void,
  isOnline: boolean,
  focusedBookmarkUid: UID | undefined,
  setFocusedBookmarkUid: (uid: UID) => void,
  togglePanel: (panel: PanelType) => void,
  panelNodeEditStart: (uid: UID) => void,
  isEmbeded: boolean | undefined, // TODO: Narrow down
  enableAiSummaryStatementFeedback: boolean | undefined, // TODO: Narrow down
  isLockedByOtherUser: boolean | undefined, // TODO: Narrow down
) {
  let isDisplayFull = false;
  let isUngated = false;
  let onlyDisplaySectionTitles = false;
  let canBeEditing = false;

  switch (displayMode) {
    case displayModes.STUDENT:
      isDisplayFull = false;
      isUngated = false;
      onlyDisplaySectionTitles = false;
      canBeEditing = false;
      break;
    case displayModes.UNGATED:
      isDisplayFull = false;
      isUngated = true;
      onlyDisplaySectionTitles = false;
      canBeEditing = !isLockedByOtherUser;
      break;
    case displayModes.FULL:
      isDisplayFull = true;
      isUngated = true;
      onlyDisplaySectionTitles = false;
      canBeEditing = !isLockedByOtherUser;
      break;
    case displayModes.OUTLINE:
      isDisplayFull = true;
      isUngated = true;
      onlyDisplaySectionTitles = true;
      canBeEditing = false;
      break;
    default:
      isDisplayFull = false;
      isUngated = false;
      onlyDisplaySectionTitles = false;
      canBeEditing = false;
  }

  return {
    displayMode: displayMode,
    setDisplayMode: setDisplayMode,
    isDisplayFull: isDisplayFull,
    isUngated: isUngated,
    onlyDisplaySectionTitles: onlyDisplaySectionTitles,
    isOnline: isOnline,
    canBeEditing: canBeEditing,
    focusedBookmarkUid: focusedBookmarkUid,
    setFocusedBookmarkUid: setFocusedBookmarkUid,
    togglePanel: togglePanel,
    panelNodeEditStart: panelNodeEditStart,
    isEmbeded: isEmbeded,
    enableAiSummaryStatementFeedback: enableAiSummaryStatementFeedback,
  };
}

export const weakMemoizedGenerateEditingContext = memoize(generateEditingContext);
export function generateEditingContext(
  shouldShowEditor: true,
  canBeEditing: boolean, // displayContext.canBeEditing
  isVersionEditActivated: boolean,
  changesDisplayMode: ChangesDisplayMode | undefined, // TODO: Narrow down
  isPublishedVersion: boolean,
  isMaintenanceVersion: boolean,
  canEditOM: boolean,
  nodeDefinitions: NodeDefinitions,
  currentUserId: number,
  changeAuthorIds: number[],
  addChanges: (change: Change[], variantId?: number) => void,
  setCurrentDocSelection: (selection: DocSelection) => void,
  clearCurrentDocSelection: () => void,
  setCurrentMenu: (menu: DocMenuName, data?: Record<string, any>) => void,
  closeCurrentMenu: () => void,
  setLocalClipboard: (data: LocalClipboardData) => void,
  editors: EditorSpecialType[] | undefined,
  allowLearningObjectivesEdit: string,
) {
  const isEditing = canBeEditing && isVersionEditActivated
    && !(isPublishedVersion || ([changesDisplayModes.HIDE_SUGGESTIONS, changesDisplayModes.ONLY_PUBLISHED] as ChangesDisplayMode[]).includes(changesDisplayMode))
    && !(isMaintenanceVersion && !canEditOM);

  return {
    shouldShowEditor,
    isVersionEditActivated,
    isEditing,
    changesDisplayMode,
    nodeDefinitions,
    colorLookup: generateColorLookup(uniq([currentUserId].concat(changeAuthorIds))),
    addChange: (change: Change, variantId?: number) => addChanges([change], variantId),
    addChanges: addChanges,
    setCurrentDocSelection,
    clearCurrentDocSelection,
    setCurrentMenu,
    closeCurrentMenu,
    setLocalClipboard,
    editors,
    allowLearningObjectivesEdit,
  };
}

export const weakMemoizedGenerateEditingContextAllFalse = memoize(generateEditingContextAllFalse);
export function generateEditingContextAllFalse(
  currentUserId: number = 1,
  changeAuthorIds: Array<number> = [],
): EditingContextAllFalse {
  return {
    shouldShowEditor: false,
    isVersionEditActivated: false,
    isEditing: false,
    colorLookup: generateColorLookup(uniq([currentUserId].concat(changeAuthorIds))),
  };
}

export const weakMemoizedGenerateIntegrationInfo = memoize(generateIntegrationInfo);
export function generateIntegrationInfo (
  final_diagnoses,
  clinical_excellence,
  basic_science_disciplines,
) {
  return {
    final_diagnoses,
    clinical_excellence,
    basic_science_disciplines,
  }
}
