import React from 'react';
import ReactDOM from 'react-dom';
import GenSortTable from 'Gen/SortTable/component'

import TableDownArrowImage from "images/table-down_arrow.png";
import TableUpArrowImage from "images/table-up_arrow.png";

class StudentLearningProgressTable extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      search: '',
      filteredData: this.props.rows,
      sortCol: 'status',
      sortDir: 'asc',
      student_name: '',
      status: '',
      assessment_type: '',
      assessment_date: ''
    };
  }

  sortProgresses = (sortCol, sortDir) =>{
    var data = this.state.filteredData
    if(sortCol === "progress_indicator_text_late" || sortCol === "progress_indicator_text_early"){
      data = data.filter(item => item[sortCol] !== 'N/A')
      const order = ['On Track', 'Below Target', 'Off Track'];
      if(sortDir==="asc"){
        data.sort((a, b) => {
          const aIndex = order.indexOf(a[sortCol]);
          const bIndex = order.indexOf(b[sortCol]);
          return aIndex - bIndex;
        });
      }else{
        data.sort((a, b) => {
          const aIndex = order.indexOf(a[sortCol]);
          const bIndex = order.indexOf(b[sortCol]);
          return bIndex - aIndex;
        });
      }
      data = data.concat(this.state.filteredData.filter(item => item[sortCol] === 'N/A'))
    }else {
      data.sort((a, b) => {
        let fa = a[sortCol].toLowerCase(),
          fb = b[sortCol].toLowerCase();
        if (fa < fb) {
          return sortDir == 'asc' ? -1 : 1;
        }
        if (fa > fb) {
          return sortDir == 'asc' ? 1 : -11;
        }
        return 0;
      });
    }
    return data
  }

  sortImage = (sortCol)=>{
    return this.state.sortCol == sortCol ? this.state.sortDir == 'asc' ? TableDownArrowImage : TableUpArrowImage : TableDownArrowImage
  }

  filtersRow() {
    if (this.props.multiple_cohorts) {
      return (
        <div>
          <div className="filtersRow">
            <div className="form-inline">
              <select className= 'form-control filterSelect' value={this.state.assessment_date || "Assessment Date"} onChange={(val) => {
                  this.setState(this.setFilters('assessment_date', val.target.value))
                }}>
                <option value="">Assessment Date</option>
                {this.props.assessment_date_options.map(function(option){
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>

              <select className= 'form-control filterSelect' value={this.state.assessment_type || "Discipline"} onChange={(val) => {
                  this.setState(this.setFilters('assessment_type', val.target.value))
                }}>
                <option value="">Discipline</option>
                {this.props.discipline_options.map(function(option){
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      )
    }
  }

  setFilters(currentFilterCol, value){
    var filters = {}
    filters[currentFilterCol] = value
    if(currentFilterCol == 'assessment_date'){
      filters.filteredData = this.props.rows.filter((item) => item.assessment_dates.indexOf(value) > -1 &&
        item.assessment_type.indexOf(this.state.assessment_type) > -1
      )
    }
    else if(currentFilterCol == 'assessment_type') {
      filters.filteredData = this.props.rows.filter((item) => item.assessment_type.toLowerCase().indexOf(value.toLowerCase()) > -1 &&
        item.assessment_dates.indexOf(this.state.assessment_date) > -1
      )
    }
    return filters
  }

  getAdditionalDetails(index, details) {
    if (this.props.report_type != 'multi-cohort') {
      if(details.length == 1) {
        return (
          <ul style={{marginLeft: '-25px'}}>
            <li key={`na${index}`}>{details[0]}</li>
          </ul>
        )
      } else if(details.length == 3) {
        return (
          <ul style={{marginLeft: '-25px'}}>
            <li key={`accuracy${index}`}>{details[0]}</li>
            <li key={`efficiency${index}`}>{details[1]}</li>
            <li key={`certainty${index}`}>{details[2]}</li>
          </ul>
        )
      }
    }
  }

  searchResults(e) {
    this.setState({
      filteredData: this.props.rows.filter((item) => {
        return (item.student_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
        item.status.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
        (this.props.report_type == 'multi-cohort' &&
        (item.assessment_type.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 ||
        item.assessment_dates.indexOf(e.target.value) > -1))
      )}),
      search: e.target.value
    });
  }

  render() {
    return <>
      <div className="detailsSection">
        <div className="col-md-12">
          <div className="sectionTitleRow">
            <div className="educator-detail-heading">
              <h2 className="reportTopSectionTitle">{this.props.purpose == "Early" ? "Early -" : ""} Individual Student Learning Progress</h2>
              {(() => {
                if (this.props.report_type == 'multi-cohort' || this.props.purpose == 'Late') {
                  return (<div className="info-bubble">
                    <span className="tooltip-box small after above-center tab-1">
                      Assessment details are displayed in early/late pairs. If both early and late data are available, the LPI displays for both with all other fields displaying for the late assessment. If there is no late assessment, the late LPI displays as N/A and all other details are matched with the early assessment.
                    </span>
                  </div>)
                }
              })()}
            </div>
            <div className="form-group" style={{width: '400px'}}>
              <input type="text" name="search" placeholder='Search:' className="form-control" onChange={e => this.searchResults(e)} />
            </div>
          </div>

          {this.filtersRow()}

          <table className="detailsTable">
            <thead>
              <tr>
                <th
                  key="student-name"
                  rowSpan="2"
                  className="bottomSectionGraphLegendText progress-table-head sortable"
                  width="200"
                  onClick={() => this.setState({
                    sortCol: 'student_name',
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortProgresses('student_name', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })
                  }
                >Student Name<img src={this.sortImage('student_name')} height='20' width='20' /></th>
                <th key="learning-progress" colSpan={(this.props.report_type != 'multi-cohort' || this.props.purpose != 'Late') ? 2 : 0}
                  className="bottomSectionGraphLegendText progress-table-head sortable"
                  width="250"
                  style={{textAlign: 'center'}}
                  onClick={() => {
                    var sortColName= (this.props.report_type == 'multi-cohort' || this.props.purpose === 'Late') ? 'progress_indicator_text_late' : 'progress_indicator_text_early'
                    this.setState({
                    sortCol: sortColName,
                    sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                    filteredData: this.sortProgresses(sortColName, this.state.sortDir == 'asc' ? 'desc' : 'asc')
                    })}
                  }
                >
                  Learning Progress
                  <img src={this.sortImage((this.props.report_type == 'multi-cohort' || this.props.purpose === 'Late') ? 'progress_indicator_text_late' : 'progress_indicator_text_early')} height='20' width='20' />
                  {(() => {
                    if (this.props.report_type == 'multi-cohort' || this.props.purpose === 'Late') {
                      return (
                        <div className="info-bubble" style={{marginLeft: '95%', marginTop: '-25px'}}>
                          <span className="tooltip-box mid after">
                            Sort by late LPI
                          </span>
                        </div>
                      )
                    }
                  })()}

                </th>
                {(() => {
                  if (this.props.report_type != 'multi-cohort') {
                    return (
                      <th key="additional-details" className="bottomSectionGraphLegendText progress-table-head" rowSpan="2">Additional Details</th>
                    )
                  }
                })()}
                <th key="case-completion" className="bottomSectionGraphLegendText progress-table-head" rowSpan="2">
                  <span>
                    Case Completion
                  </span>
                  <div className="info-bubble" style={{marginLeft: '150px', marginTop: '-22px'}}>
                    <span className="tooltip-box mid after">
                      Case completion for all {this.props.discipline} cases at the time of assessment
                    </span>
                  </div>
                </th>
                <th key="exam-status" className="bottomSectionGraphLegendText progress-table-head" rowSpan="2">Exam Status</th>
                {(() => {
                  if (this.props.report_type == 'multi-cohort') {
                    return ([
                      <th key="assessment-types" className="bottomSectionGraphLegendText progress-table-head" rowSpan="2">Assessment Type</th>,
                      <th key="assessment-dates" className="bottomSectionGraphLegendText progress-table-head" rowSpan="2">Assessment Dates</th>
                    ])
                  }
                })()}
              </tr>
              {(() => {
                if (this.props.report_type == 'multi-cohort' || this.props.purpose === 'Late') {
                  return (
                    <tr>
                      <th className="bottomSectionGraphLegendText small" style={{textAlign: 'center', fontSize: '12px'}}>Early</th>
                      <th className="bottomSectionGraphLegendText small" style={{textAlign: 'center', fontSize: '12px'}}>Late</th>
                    </tr>
                  )
                }
              })()}

            </thead>
            <tbody>
              {this.state.filteredData.map((row, i) => {
                return <tr key={`studentLearningProgressTable${i}`}>
                  <td>{row.student_name}</td>
                  <td>
                    <div className={`progressIndicatorBox ${row.progress_indicator_color_early}`}><div className="progressIndicatorText">{row.progress_indicator_text_early}</div></div>
                  </td>
                  <td>
                    <div className={`progressIndicatorBox ${row.progress_indicator_color_late}`}><div className="progressIndicatorText">{row.progress_indicator_text_late}</div></div>
                  </td>
                  {(() => {
                    if (this.props.report_type != 'multi-cohort') {
                      return (
                        <td>
                          {this.getAdditionalDetails(i, row.additional_details)}
                        </td>
                      )
                    }
                  })()}
                  <td>
                    <div className="row">
                      <div className="col-md-12">
                        <span>{row.case_completion_fraction}</span>
                        <div className="mt-1"><a target="_blank" href={row.student_case_log_path}>Student Case Log Report</a></div>
                      </div>
                    </div>
                  </td>
                  <td>{row.status}</td>
                  {(() => {
                  if (this.props.report_type == 'multi-cohort') {
                    return ([
                      <td>{row.assessment_type}</td>,
                      <td>{row.assessment_dates}</td>
                    ])
                  }
                })()}
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  }
}

export default StudentLearningProgressTable;
